import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IonicSlides, ModalController } from '@ionic/angular';
import { SubscriptionLike } from 'rxjs';
import SwiperCore, { Autoplay, Keyboard, Pagination, Scrollbar } from 'swiper';
import { Image } from 'viamondo-core/data';

SwiperCore.use([Autoplay, Keyboard, Pagination, Scrollbar, IonicSlides]);

@Component({
  selector: 'app-image',
  templateUrl: './image.modal.html',
  styleUrls: ['./image.modal.scss']
})
export class ImageModal implements OnInit, OnDestroy {
  private locationSubscription: SubscriptionLike;

  @Input()
  index: number;

  @Input()
  images: Image[] = [];

  constructor(private modalController: ModalController, private location: Location) {}

  ngOnInit(): void {
    this.location.go(`${this.location.path(false)}#image-modal`);
    this.locationSubscription = this.location.subscribe(() => this.modalController.dismiss());
  }

  ngOnDestroy(): void {
    this.locationSubscription.unsubscribe();
  }

  hide(): void {
    this.location.back();
  }
}
