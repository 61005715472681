<div class="image-list">
  <div *ngFor="let index of imageIndexes" class="photo-border">
    <img
      *ngIf="images[index]"
      [images-src]="images[index]"
      data-gravity="center"
      data-quality="low"
      height="58"
      width="58"
      tappable
      (click)="changeImage(index)"
    />
    <button *ngIf="!images[index]" class="photo-filler" (click)="addImage(index)">
      <ion-icon name="camera"></ion-icon>
    </button>
  </div>
</div>
