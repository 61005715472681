import { FullOwnTripTO, FullTripTO, NewsItemTripTO } from '../../api';

import { Basic } from './basic';
import { BasicTypes } from './basic-types.enum';
import { ISOString } from './isostring';
import { MinimalPlace } from './minimal-place';
import { Route } from './route';

export class Trip extends Basic {
  name: string;
  description: string;
  startDate?: ISOString;
  endDate?: ISOString;
  duration: number;

  places: MinimalPlace[] = [];
  routes: Route[] = [];

  static empty(): Trip {
    const trip = new Trip();
    trip.setInitialPrivacy();
    return trip;
  }

  static fromBackend(backendTrip: FullTripTO | FullOwnTripTO | NewsItemTripTO): Trip {
    const trip = new Trip();
    trip.setCreator();
    trip.setCommon(backendTrip._id, backendTrip.images);
    trip.setReactions(backendTrip.likes);

    trip.name = backendTrip.name;
    trip.description = backendTrip.description;
    trip.startDate = backendTrip.startDate;
    trip.endDate = backendTrip.endDate;
    trip.duration = backendTrip.duration;

    if (Trip.isFullTrip(backendTrip)) {
      trip.setCreator(backendTrip.participants[0]);
      trip.places = backendTrip.places.map(p => MinimalPlace.fromBackend(p));
    }

    if (Trip.isOwnTrip(backendTrip)) {
      trip.privacy = backendTrip.privacy;
    }
    return trip;
  }

  constructor() {
    super(BasicTypes.TRIP);
  }

  override updateValues(formValues: any): void {
    super.updateValues(formValues);
    this.duration = Basic.dateDiffInDays(this.startDate, this.endDate);
  }

  private static isFullTrip(trip: FullTripTO | FullOwnTripTO | NewsItemTripTO): trip is FullTripTO {
    return (trip as FullTripTO).places !== undefined;
  }

  private static isOwnTrip(trip: FullTripTO | FullOwnTripTO | NewsItemTripTO): trip is FullOwnTripTO {
    return (trip as FullOwnTripTO).privacy !== undefined;
  }
}
