<div
  *ngIf="mapConfig"
  [id]="id"
  class="map"
  leaflet
  [leafletZoom]="mapConfig.zoom"
  (leafletMapReady)="onMapReady($event)"
  [leafletOptions]="mapConfig.options"
  [leafletCenter]="mapConfig.center"
>
  <div *ngFor="let l of mapConfig.markers" [leafletLayer]="l"></div>
</div>
