<ion-header *ngIf="displayType === 'modal'">
  <ion-toolbar color="primary">
    <ion-title>Notifications</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="hide()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-refresher (ionRefresh)="doRefresh($event)" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <div *ngIf="!loaded && isOnline" class="my-spinner"><ion-spinner></ion-spinner></div>
  <div *ngIf="!isOnline">
    <p class="padding">Please go online to see your notifications.</p>
  </div>
  <ion-list *ngIf="loaded && isOnline">
    <ion-item *ngIf="notifications.length === 0">
      <ion-label>You do not have any notifications.</ion-label>
    </ion-item>
    <ion-item
      class="item-user"
      *ngFor="let item of notifications"
      [ngClass]="{'new': item.new, 'item-button-right': (item.action === 'follow') }"
    >
      <users-profile-pic slot="start" [user]="item.user" [size]="40"></users-profile-pic>
      <ion-label *ngIf="item.action === 'like'" class="ion-text-wrap">
        <p>
          <a (click)="viewProfile(item.user)">{{ item.user.firstName }}</a> liked
          <a (click)="viewTarget(item)">{{ item.target.text }}.</a>
        </p>
        <p class="align-icon-and-text">
          <ion-icon src="/assets/fontawesome/solid/heart.svg"></ion-icon>
          <time [dateTime]="item.datetime">{{ item.datetime | timeago }}</time>
        </p>
      </ion-label>

      <ion-label *ngIf="item.action === 'comment'">
        <p>
          <a (click)="viewProfile(item.user)">{{ item.user.firstName }}</a> commented on
          <a (click)="viewTarget(item)">{{ item.target.text }}.</a>
        </p>
        <p class="align-icon-and-text">
          <ion-icon src="/assets/fontawesome/solid/comment.svg"></ion-icon>
          <time [dateTime]="item.datetime">{{ item.datetime | timeago }}</time>
        </p>
      </ion-label>

      <ion-label *ngIf="item.action === 'flag'">
        <p>
          <a (click)="viewProfile(item.user)">{{ item.user.firstName }}</a> saved
          <a (click)="viewTarget(item)">{{ item.target.text }}.</a>
        </p>
        <p class="align-icon-and-text">
          <ion-icon src="/assets/fontawesome/solid/bookmark.svg"></ion-icon>
          <time [dateTime]="item.datetime">{{ item.datetime | timeago }}</time>
        </p>
      </ion-label>

      <ion-label *ngIf="item.action === 'follow'">
        <p><a (click)="viewProfile(item.user)">{{ item.user.firstName }}</a> started following you.</p>
        <p class="align-icon-and-text">
          <ion-icon src="/assets/fontawesome/solid/user-plus.svg"></ion-icon>
          <time [dateTime]="item.datetime">{{ item.datetime | timeago }}</time>
        </p>
      </ion-label>

      <ion-button
        slot="end"
        *ngIf="item.action === 'follow'"
        color="primary"
        [fill]="item.user.group ? 'solid' : 'outline'"
        [users-select-group]="item.user"
      >
        <ion-icon name="person-add" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-item>
  </ion-list>

  <ion-infinite-scroll *ngIf="loaded && isOnline" (ionInfinite)="loadMore($event)" threshold="10%">
    <ion-infinite-scroll-content></ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
