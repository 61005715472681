import { ErrorHandler as AngularErrorHandler, APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { IonicModule, IonicRouteStrategy, ToastController } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';

import { environment } from '../environments/environment';

import { AppInitializerService } from './app-initializer.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiModule } from './core/api';
import { ErrorHandler } from './error-handler';
import { NotFoundComponent } from './not-found/not-found.component';
import { UiModule } from './ui';

const appInit =
  (appInitializer: AppInitializerService): (() => Promise<void>) =>
  () =>
    appInitializer.loadAll();

@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    NgxSpinnerModule,
    AppRoutingModule,
    ApiModule,
    UiModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerWhenStable:10000' })
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: APP_INITIALIZER, useFactory: appInit, deps: [AppInitializerService], multi: true },
    { provide: AngularErrorHandler, useClass: ErrorHandler, deps: [ToastController, NgxSpinnerService] }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
