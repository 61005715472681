import { GeoLocationTO, MinimalPlaceTO, MinimalTripTO, NotificationTO, RouteTO } from 'viamondo-core/api';

import { ISOString } from './isostring';
import { SocialUser } from './social-user';

export class Notification {
  datetime: ISOString;
  action: 'like' | 'flag' | 'follow' | 'comment';
  new: boolean;
  user: SocialUser;
  target: {
    id: string;
    basicType: 'Trip' | 'Place' | 'Route' | 'User';
    text: string;
  };

  constructor(notificationTO: NotificationTO) {
    this.datetime = notificationTO.datetime;
    this.action = notificationTO.action;
    this.new = !notificationTO.seen;
    this.user = new SocialUser(notificationTO.userId);

    if (['Trip', 'Place', 'Route'].indexOf(notificationTO.targetType) >= 0) {
      this.target = {
        id: notificationTO.target._id,
        basicType: notificationTO.targetType,
        text: ''
      };

      switch (notificationTO.targetType) {
        case 'Trip':
          const trip = notificationTO.target as MinimalTripTO;
          this.target.text = `your trip '${trip.name}'`;
          break;
        case 'Place':
          const place = notificationTO.target as MinimalPlaceTO;
          this.target.text = `your place at ${place.venueId.name} in ${this.convertLocation(place.venueId.location)}`;
          break;
        case 'Route':
          const route = notificationTO.target as RouteTO;
          this.target.text = `your route from ${this.convertLocation(route.fromLocation)} to ${this.convertLocation(route.fromLocation)}`;
          break;
      }
    }
  }

  private convertLocation(location: GeoLocationTO): string {
    return `${location.city}, ${location.country}`;
  }
}
