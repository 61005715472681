import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { DatetimeInputComponent } from './components/datetime-input/datetime-input.component';
import { PrivacyInputComponent } from './components/privacy-input/privacy-input.component';
import { PrivacyModalComponent } from './components/privacy-modal/privacy-modal.component';
import { PrivacyPipe } from './pipes/privacy.pipe';

@NgModule({
  declarations: [DatetimeInputComponent, PrivacyInputComponent, PrivacyModalComponent, PrivacyPipe],
  imports: [CommonModule, IonicModule, FormsModule, ReactiveFormsModule],
  exports: [ReactiveFormsModule, DatetimeInputComponent, PrivacyInputComponent]
})
export class CustomFormsModule {}
