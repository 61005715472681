import { HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Camera, CameraResultType, CameraSource, GalleryImageOptions, ImageOptions } from '@capacitor/camera';
import { Filesystem, ReadFileResult } from '@capacitor/filesystem';
import { Platform } from '@ionic/angular';
import { environment } from 'environments/environment';
import { forkJoin, from, Observable, of } from 'rxjs';
import { concatMap, filter, map, tap } from 'rxjs/operators';

import { Image } from '../models/image';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  private uploadStats = [];

  constructor(private http: HttpClient, private platform: Platform) {}

  uploadImages(folderName: string, images: Image[]): Observable<Image[]> {
    const savedImages = images.filter(img => img !== null && img !== undefined && img.publicId);
    const unSavedImages = images.filter(img => img !== null && img !== undefined && img.local);

    if (unSavedImages.length === 0) {
      return of(savedImages);
    } else {
      return this.bulkUploadImages(unSavedImages, folderName).pipe(map(uploadedImages => savedImages.concat(uploadedImages)));
    }
  }

  private bulkUploadImages(images: Image[], folderName: string): Observable<Image[]> {
    return forkJoin(images.map((image, index) => this.upload(image, folderName, index)));
  }

  private upload(image: Image, folder: string, index: number): Observable<Image> {
    return this.getImageData(image).pipe(
      concatMap(fileData => this.buildRequest(fileData, folder)),
      tap(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.updateStats(index, event.loaded, event.total);
        }
      }),
      filter(event => event instanceof HttpResponse),
      map(event => {
        if (event instanceof HttpResponse) {
          const response = event.body;
          return {
            publicId: response['public_id'],
            type: response['type'],
            resourceType: response['resource_type'],
            version: response['version'],
            url: response['secure_url'],
            primary: image.primary
          };
        }
        throw new Error(`Cloudinary response was not an HttpResponse: ${JSON.stringify(event)}`);
      })
    );
  }

  private getImageData(image: Image): Observable<string> {
    if (image.filePath) {
      return from(Filesystem.readFile({ path: image.filePath })).pipe(map((file: ReadFileResult) => `data:image/jpeg;base64,${file.data}`));
    } else {
      return of(image.webPath);
    }
  }

  private buildRequest(fileData: string, folder: string): Observable<HttpEvent<any>> {
    const cloudinaryOptions = environment.cloudinaryConfig;
    const url = `${cloudinaryOptions.protocol}//api.cloudinary.com/v1_1/${cloudinaryOptions.cloud_name}/image/upload`;

    const formData: FormData = new FormData();
    formData.append('file', fileData);
    formData.append('folder', folder);
    formData.append('upload_preset', cloudinaryOptions.upload_preset);

    const req = new HttpRequest('POST', url, formData, { reportProgress: true });
    return this.http.request(req);
  }

  private updateStats(index: number, loaded: number, total: number): void {
    this.uploadStats[index] = { loaded, total };
    const percent =
      (this.uploadStats.map(value => value.loaded).reduce((a, b) => a + b, 0) /
        this.uploadStats.map(value => value.total).reduce((a, b) => a + b, 1)) *
      100;
    console.log(`Uploaded ${percent}%`);
  }

  takePhoto(): Observable<Image> {
    // TODO: Additionally, because the Camera API launches a separate Activity to handle taking the photo,
    // you should listen for appRestoredResult in the App plugin to handle any camera data that was sent
    // in the case your app was terminated by the operating system while the Activity was running.
    const options: ImageOptions = {
      quality: 90,
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      saveToGallery: true // TODO: Save images in a separate ViaMondo folder
    };

    return from(Camera.getPhoto(options)).pipe(
      map(photo => ({
        publicId: undefined,
        type: undefined,
        resourceType: undefined,
        version: undefined,
        url: undefined,
        local: true,
        webPath: photo.webPath,
        filePath: photo.path,
        primary: false
      }))
    );
  }

  selectPhotos(limit: number): Observable<Image[]> {
    if (this.isBrowser()) {
      return this.selectPhotosBrowser(limit);
    } else {
      return this.selectPhotosApp(limit);
    }
  }

  private isBrowser(): boolean {
    return this.platform.is('pwa') || this.platform.is('mobileweb') || this.platform.is('desktop');
  }

  private selectPhotosApp(limit: number): Observable<Image[]> {
    const options: GalleryImageOptions = {
      quality: 90,
      limit
    };

    return from(Camera.pickImages(options)).pipe(
      map(photos =>
        photos.photos.map(photo => ({
          publicId: undefined,
          type: undefined,
          resourceType: undefined,
          version: undefined,
          url: undefined,
          local: true,
          webPath: photo.webPath,
          filePath: photo.path,
          primary: false
        }))
      )
    );
  }

  private selectPhotosBrowser(limit: number): Observable<Image[]> {
    const promiseResult = this.selectFiles(limit).then((files: File[]) => Promise.all(files.map(f => this.readImage(f))));
    return from(promiseResult);
  }

  private selectFiles(limit: number): Promise<File[]> {
    return new Promise(resolve => {
      const input = document.createElement('input');
      input.type = 'file';
      input.multiple = limit > 1 ? true : false;
      input.accept = 'image/*';
      input.onchange = () => {
        const files = Array.from(input.files);
        resolve(files.slice(0, limit));
      };
      input.click();
    });
  }

  private readImage(file: any): Promise<Image> {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        resolve({
          publicId: undefined,
          type: undefined,
          resourceType: undefined,
          version: undefined,
          url: undefined,
          local: true,
          webPath: event.target.result,
          filePath: undefined,
          primary: false
        });
      };
      reader.readAsDataURL(file);
    });
  }
}
