import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Place, PlacesService, Trip, TripsService } from 'viamondo-core/data';

import { LikesModal } from '../../modals/likes/likes.modal';

@Component({
  selector: 'app-reactions',
  templateUrl: './reactions.component.html',
  styleUrls: ['./reactions.component.scss']
})
export class ReactionsComponent {
  @Input()
  item: Trip | Place;

  constructor(private modalCtrl: ModalController, private tripsService: TripsService, private placesService: PlacesService) {}

  async showLikesList(): Promise<void> {
    const likesModal = await this.modalCtrl.create({
      component: LikesModal,
      componentProps: {
        likesList: this.item.likes
      }
    });
    return await likesModal.present();
  }

  toggleLike(): void {
    if (this.item instanceof Trip) {
      this.tripsService.toggleLike(this.item);
    } else {
      this.placesService.toggleLike(this.item);
    }
  }
}
