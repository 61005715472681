import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { Venue } from 'viamondo-core/data';
import { CategoriesService, CategoryInfo } from 'viamondo-core/places';

import { SingleMapCreator } from '../../../maps/models/single-map-config';
import { MapService } from '../../../maps/services/map.service';

@Component({
  selector: 'app-venue-info-modal',
  templateUrl: './venue-info.modal.html',
  styleUrls: ['./venue-info.modal.scss']
})
export class VenueInfoModal {
  private _venue: Venue;
  private _category: CategoryInfo;
  private _mapDataCreator?: SingleMapCreator;

  @Input()
  set venue(value: Venue) {
    this._venue = value;
    this._category = this.categoriesService.info(value.category);
    this._mapDataCreator = () => this.mapService.getMapForVenue(value);
  }

  get venue(): Venue {
    return this._venue;
  }

  get category(): CategoryInfo {
    return this._category;
  }

  get mapDataCreator(): SingleMapCreator | undefined {
    return this._mapDataCreator;
  }

  get locationUrl(): SafeUrl {
    // eslint-disable-next-line max-len
    // Backup for browsers: https://www.openstreetmap.org/?mlat=${this.venue.location.lat}&mlon=${this.venue.location.lng}#map=17/${this.venue.location.lat}/${this.venue.location.lng}
    // TODO: Does not work on iOS - Failed to open URL geo:0,0?q=37.33262674912818,-122.03045105543795
    return this.sanitizer.bypassSecurityTrustUrl(`geo:0,0?q=${this.venue.location.lat},${this.venue.location.lng}`);
  }

  constructor(
    private sanitizer: DomSanitizer,
    private modalController: ModalController,
    private categoriesService: CategoriesService,
    private mapService: MapService
  ) {}

  close(): void {
    this.modalController.dismiss();
  }
}
