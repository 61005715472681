import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import { BackendService, LoginResponse, SignupResponse } from 'viamondo-core/api';
import { LoginRequestBody } from 'viamondo-core/api/models/authentication-requests';
import { LocalStorageService } from 'viamondo-core/local-storage.service';

import { CreateUserTO } from '../models/authentication-models';
import { NextSteps } from '../models/next-steps';

import { BookmarksStateService } from './bookmarks-state.service';
import { FeedsStateService } from './feeds-state.service';
import { NotificationsService } from './notifications.service';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(
    private backendService: BackendService,
    private localStorageService: LocalStorageService,
    private bookmarksStateService: BookmarksStateService,
    private notificationsService: NotificationsService,
    private usersService: UsersService,
    private feedsStateService: FeedsStateService
  ) {}

  login(formValues: LoginRequestBody): Observable<NextSteps> {
    return this.backendService.login(formValues).pipe(
      tap(response => this.storeToken(response)),
      concatMap(response => this.feedsStateService.load().pipe(map(() => response))),
      map(response => ({ changePassword: response.user.changePassword }))
    );
  }

  logout(): Observable<void> {
    this.localStorageService.clear();
    this.usersService.clear();
    this.bookmarksStateService.clear();
    this.notificationsService.stop();
    this.feedsStateService.clear();
    // TODO: Check for other logout things to do
    return this.backendService.logout().pipe(map(() => undefined));
  }

  signup(formValues: CreateUserTO): Observable<void> {
    return this.backendService.signup(formValues).pipe(
      tap(response => this.storeToken(response)),
      map(() => undefined)
    );
  }

  resetPassword(email: string): Observable<void> {
    return this.backendService.resetPassword({ email }).pipe(map(() => undefined));
  }

  changePassword(oldPassword: string, newPassword: string): Observable<void> {
    return this.backendService.changePassword({ oldPassword, newPassword }).pipe(map(() => undefined));
  }

  private storeToken(response: LoginResponse | SignupResponse): void {
    this.localStorageService.setToken(response.token);
    this.localStorageService.setCurrentUserId(response.user._id);
    this.usersService.setCurrentUser(response.user);
    this.notificationsService.start();
    this.bookmarksStateService.loadAll().subscribe();
  }
}
