import { Injectable } from '@angular/core';
import { Geolocation } from '@capacitor/geolocation';
import { AlertController, ModalController } from '@ionic/angular';
import { differenceInMinutes } from 'date-fns';
import { from, Observable, of, Subject } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { SelectCityModal } from 'viamondo-ui';

import { GeoLocation } from '../data';

import { GeocodingService } from './geocoding.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  private cachedLocation?: GeoLocation;
  private cachedTime?: Date;

  constructor(
    private alertController: AlertController,
    private modalController: ModalController,
    private geocodingService: GeocodingService
  ) {}

  getCoordinates(): Promise<GeolocationPosition> {
    return Geolocation.getCurrentPosition({
      maximumAge: 0,
      timeout: 10000,
      enableHighAccuracy: true
    }).catch(error => {
      console.error('geolocation error', error);
      // TODO: Handle offline case!
      // var position = Offline.getCachedAreaParams(Offline.getUsedAreaIndex()) || {};
      // return {
      //   lat: position.lat,
      //   lng: position.lng,
      //   timestamp: Date.now()
      // };
      throw error;
    });
  }

  getLocation(force = false): Observable<GeoLocation> {
    if (force || !this.cachedLocation || differenceInMinutes(new Date(), this.cachedTime) > 30) {
      this.cachedTime = new Date();
      return from(this.getCoordinates()).pipe(
        mergeMap(resp => this.geocodingService.reverse(resp.coords.latitude, resp.coords.longitude)),
        tap(location => (this.cachedLocation = location))
      );
    } else {
      this.cachedTime = new Date();
      return of(this.cachedLocation);
    }
  }

  getLocationWithFallback(force = false): Observable<GeoLocation> {
    return this.getLocation(force).pipe(
      catchError(() => this.showErrorAlert()),
      tap(location => (this.cachedLocation = location))
    );
  }

  setLocationManually(location: GeoLocation): void {
    this.cachedLocation = location;
    this.cachedTime = new Date();
  }

  private showErrorAlert(): Observable<GeoLocation> {
    const subject = new Subject<GeoLocation>();
    this.alertController
      .create({
        header: 'Problems locating you',
        message: `We were unable to determie your current location. Please make sure you have your location enabled and
      given ViaMondo permission to use your location. Alternatively you can manually select your location.`,
        buttons: [
          {
            text: 'Try again',
            role: 'cancel',
            handler: () => {
              this.getLocationWithFallback(true).subscribe(result => {
                subject.next(result);
                subject.complete();
              });
            }
          },
          {
            text: 'Select city',
            handler: () => {
              this.changeCity().subscribe(result => {
                subject.next(result);
                subject.complete();
              });
            }
          }
        ]
      })
      .then(alert => {
        alert.present();
      });
    return subject.asObservable();
  }

  private changeCity(): Observable<GeoLocation> {
    const subject = new Subject<GeoLocation>();
    this.modalController
      .create({
        component: SelectCityModal,
        componentProps: { allowCurrentLocation: false, allowClose: false },
        backdropDismiss: false
      })
      .then(modal => {
        modal.onWillDismiss().then(event => {
          if (event.data) {
            subject.next(event.data);
            subject.complete();
          }
        });
        modal.present();
      });
    return subject.asObservable();
  }
}
