import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { ChartsModule } from './charts/charts.module';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LazyLoadListComponent } from './components/lazy-load-list/lazy-load-list.component';
import { CustomFormsModule } from './forms/forms.module';
import { ImagesModule } from './images/images.module';
import { MapsModule } from './maps/maps.module';
import { PlacesModule } from './places/places.module';
import { SharedModule } from './shared/shared.module';
import { TripsModule } from './trips/trips.module';
import { UsersModule } from './users/users.module';

const components = [LazyLoadListComponent, HeaderComponent, FooterComponent];

const modules = [CustomFormsModule, ImagesModule, MapsModule, PlacesModule, SharedModule, TripsModule, UsersModule, ChartsModule];

@NgModule({
  declarations: [...components],
  exports: [...components, ...modules],
  imports: [CommonModule, IonicModule, RouterModule, ...modules]
})
export class UiModule {}
