<ion-header>
  <ion-toolbar color="primary">
    <ion-title>{{ users.length | pluralize:'Nobody likes this':'One person likes this':'{} people like this' }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div *ngIf="loading" class="my-spinner"><ion-spinner></ion-spinner></div>

  <ion-list>
    <users-item *ngFor="let user of users" [user]="user"></users-item>
  </ion-list>
</ion-content>
