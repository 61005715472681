<ion-item [button]="isMobile" [id]="id" [class.has-error]="invalid">
  <ion-icon src="/assets/fontawesome/solid/calendar.svg" slot="start"></ion-icon>
  <ion-label position="stacked">{{ label }}</ion-label>
  <ion-input *ngIf="isMobile" readonly placeholder="Select a date" [value]="formattedDate"></ion-input>
  <ion-input
    *ngIf="!isMobile"
    [type]="presentation === 'date' ? 'date' : 'datetime-local'"
    [value]="formattedDate"
    (ionChange)="inputChanged($event)"
  ></ion-input>
  <div class="form-control-feedback" *ngIf="invalid">
    <span *ngIf="errors['required']">Field is required.</span>
  </div>
</ion-item>

<ng-container *ngIf="isMobile">
  <ion-modal [ngClass]="{ 'datetime-modal': presentation === 'date-time', 'date-modal': presentation === 'date' }" [trigger]="id">
    <ng-template>
      <ion-content>
        <ion-datetime
          [value]="dateValue"
          (ionChange)="setDate($event)"
          (ionBlur)="onBlur()"
          [showDefaultButtons]="true"
          [presentation]="presentation"
        ></ion-datetime>
      </ion-content>
    </ng-template>
  </ion-modal>
</ng-container>
