import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { BadRequestError, ForbiddenError, GenericHttpError, NotFoundError, ServerError, UnauthorizedError } from '../models/http-errors';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
        if (this.isNotLoggedIn(error)) {
          this.router.navigate(['/', 'session', 'login']);
          return EMPTY;
        } else if (this.isOffline(error)) {
          // TODO: Handle offline error
          return EMPTY;
        } else if (error instanceof HttpErrorResponse) {
          return throwError(this.mapHttpErrors(error));
        } else {
          return throwError(error);
        }
      })
    );
  }

  private isNotLoggedIn(error: any): boolean {
    return (
      error instanceof HttpErrorResponse &&
      error.status === 401 &&
      error.error.message.code === 'invalid_token' &&
      error.error.message.message === 'invalid signature'
    );
  }

  private isOffline(error: any): boolean {
    return error instanceof HttpErrorResponse && error.status === 0;
  }

  private mapHttpErrors(error: HttpErrorResponse): Error {
    if (error.status === 400) {
      return new BadRequestError(error);
    } else if (error.status === 401) {
      return new UnauthorizedError(error);
    } else if (error.status === 403) {
      return new ForbiddenError(error);
    } else if (error.status === 404) {
      return new NotFoundError(error);
    } else if (error.status === 500) {
      return new ServerError(error);
    } else {
      return new GenericHttpError(error);
    }
  }
}
