import { Observable, ReplaySubject } from 'rxjs';

export interface CloudinaryOptions {
  type: string;
  version: number;
  crop: 'fill' | 'thumb';
  width?: number;
  height?: number;
  gravity?: 'center' | 'face:center' | 'auto';
  quality: 'auto:good' | 'auto:eco' | 'auto:low';
  effect?: string;
  opacity?: number;
  format: 'auto';
}

export class CloudinaryService {
  private readonly optionToPrefixMap = {
    crop: 'c',
    width: 'w',
    height: 'h',
    gravity: 'g',
    quality: 'q',
    effect: 'e',
    opacity: 'o',
    format: 'f'
  };
  private dprSubject = new ReplaySubject<number>(1);

  constructor(private readonly baseUrl: string) {
    if (window) {
      this.registerDprListener();
    }
  }

  url(publicId: string, options: CloudinaryOptions): string {
    const transformations = Object.keys(this.optionToPrefixMap)
      .map(key => (options[key] ? `${this.optionToPrefixMap[key]}_${options[key]}` : undefined))
      .filter(v => v !== undefined)
      .join(',');

    return `${this.baseUrl}/image/${options.type}/${transformations}/v${options.version}/${publicId}`;
  }

  dpr(): Observable<number> {
    return this.dprSubject.asObservable();
  }

  private registerDprListener(): void {
    this.dprSubject.next(window.devicePixelRatio);
    window
      .matchMedia(`(resolution: ${window.devicePixelRatio}dppx)`)
      .addEventListener('change', () => this.registerDprListener(), { once: true });
  }
}
