import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { BookmarkButtonComponent } from './components/bookmark-button/bookmark-button.component';
import { IsSubmittingDirective } from './directives/is-submitting.directive';
import { LocationPipe } from './pipes/location.pipe';
import { PluralizePipe } from './pipes/pluralize.pipe';
import { RangePipe } from './pipes/range.pipe';
import { RemoveHttpPipe } from './pipes/remove-http.pipe';
import { TimeagoPipe } from './pipes/timeago.pipe';

const components = [BookmarkButtonComponent];
const directives = [IsSubmittingDirective];
const pipes = [LocationPipe, PluralizePipe, RemoveHttpPipe, RangePipe, TimeagoPipe];

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [...components, ...pipes, ...directives],
  exports: [...components, ...pipes, ...directives]
})
export class SharedModule {}
