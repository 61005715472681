import { Component, Input } from '@angular/core';
import { MinimalPlace } from 'viamondo-core/data';

@Component({
  selector: 'places-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent {
  @Input()
  place: MinimalPlace;

  @Input()
  usage = 'default';

  isOnline = true;
}
