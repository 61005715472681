import { Pipe, PipeTransform } from '@angular/core';
import { Privacy } from 'viamondo-core/data';

@Pipe({
  name: 'privacy'
})
export class PrivacyPipe implements PipeTransform {
  transform(value?: Privacy): string {
    if (!value) {
      return '';
    }

    if (value.mode === 'public') {
      return 'everyone';
    } else if (value.mode === 'private') {
      return 'nobody';
    } else {
      return this.toSentence(value.groups);
    }
  }

  private toSentence(value: string[]): string {
    if (value.length === 0) {
      return '';
    } else if (value.length === 1) {
      return value[0];
    } else {
      return value.slice(0, value.length - 1).join(', ') + ' and ' + value.slice(-1);
    }
  }
}
