import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { CustomFormsModule } from '../forms/forms.module';
import { ImagesModule } from '../images/images.module';
import { SharedModule } from '../shared/shared.module';
import { UsersModule } from '../users/users.module';

import { CreateModalComponent } from './components/create-modal/create-modal.component';
import { InputComponent } from './components/input/input.component';
import { ItemComponent } from './components/item/item.component';
import { LinkComponent } from './components/link/link.component';
import { SelectorModalComponent } from './components/selector-modal/selector-modal.component';

@NgModule({
  imports: [IonicModule, CommonModule, CustomFormsModule, SharedModule, ImagesModule, UsersModule, RouterModule],
  declarations: [InputComponent, ItemComponent, LinkComponent, SelectorModalComponent, CreateModalComponent],
  exports: [InputComponent, ItemComponent, LinkComponent]
})
export class TripsModule {}
