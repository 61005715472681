import { UserProfileTO } from '../../api';

import { Image } from './image';
import { SocialUser } from './social-user';

export class User extends SocialUser {
  profileCover: Image;
  description: string;
  url: string;

  numFollowers: number;
  numFollowing: number;

  constructor(user: UserProfileTO, group?: string) {
    super(user, group);
    this.profileCover = user.profileCover;
    this.description = user.description;
    this.url = user.url;
    this.numFollowers = user.numFollowers;
    this.numFollowing = user.numFollowing;
  }
}
