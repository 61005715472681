import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DateValueChartComponent } from './date-value-chart/date-value-chart.component';
import { MapChartComponent } from './map-chart/map-chart.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';

@NgModule({
  declarations: [MapChartComponent, PieChartComponent, DateValueChartComponent],
  exports: [MapChartComponent, DateValueChartComponent, PieChartComponent],
  imports: [CommonModule]
})
export class ChartsModule {}
