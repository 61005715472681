import { Component, Input } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';

import { MulitMapCreator, MultiMapConfig } from '../../models/multi-map-config';
import { SingleMapConfig, SingleMapCreator } from '../../models/single-map-config';

@Component({
  selector: 'app-full-map',
  templateUrl: './full-map.component.html',
  styleUrls: ['./full-map.component.scss']
})
export class FullMapComponent {
  @Input()
  isSinglePointMap = false;

  @Input()
  mapDataCreator: () => MultiMapConfig | SingleMapConfig;

  @Input()
  text: string;

  @Input()
  displayType: 'modal' | 'popover' = 'modal';

  constructor(private modalController: ModalController, private popoverController: PopoverController) {}

  get multiMapCreator(): MulitMapCreator {
    if (this.isSinglePointMap) {
      throw new Error('Mixing single and multi point maps!');
    }
    return this.mapDataCreator as MulitMapCreator;
  }

  get singleMapCreator(): SingleMapCreator {
    if (!this.isSinglePointMap) {
      throw new Error('Mixing single and multi point maps!');
    }
    return this.mapDataCreator as SingleMapCreator;
  }

  close(): void {
    if (this.displayType === 'modal') {
      this.modalController.dismiss();
    } else {
      this.popoverController.dismiss();
    }
  }
}
