import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { Trip, TripsService } from 'viamondo-core/data';

import { PrivacyValidator } from '../../../forms';

@Component({
  selector: 'trips-create-modal',
  templateUrl: './create-modal.component.html',
  styleUrls: ['./create-modal.component.scss']
})
export class CreateModalComponent {
  trip: Trip;
  form: UntypedFormGroup;

  constructor(
    formBuilder: UntypedFormBuilder,
    private modalController: ModalController,
    private tripsServices: TripsService,
    private spinnerService: NgxSpinnerService
  ) {
    this.trip = Trip.empty();
    this.form = formBuilder.group({
      name: ['', Validators.required],
      description: [],
      startDate: [],
      endDate: [],
      privacy: [this.trip.privacy, [Validators.required, PrivacyValidator]]
    });
  }

  save(): void {
    // TODO: Add validation
    if (!this.form.valid) {
      return;
    }
    this.trip.updateValues(this.form.value);
    this.tripsServices.save(this.trip).subscribe(() => {
      this.modalController.dismiss();
      // TODO: Reload trips after creation
      this.spinnerService.hide();
    });
  }

  back(): void {
    this.modalController.dismiss();
  }
}
