import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ToastController } from '@ionic/angular';
import { lastValueFrom, Observable, of } from 'rxjs';
import { concatMap, switchMap } from 'rxjs/operators';

import { BookmarksStateService, NotificationsService, UsersService } from './core/data';
import { LocalStorageService } from './core/local-storage.service';
import { CategoriesService } from './core/places';

@Injectable({
  providedIn: 'root'
})
export class AppInitializerService {
  constructor(
    private localStorageService: LocalStorageService,
    private notificationsService: NotificationsService,
    private bookmarksStateService: BookmarksStateService,
    private usersService: UsersService,
    private categoriesService: CategoriesService,
    private swUpdate: SwUpdate,
    private toastController: ToastController
  ) {
    this.swUpdate.versionUpdates.subscribe(event => {
      if (event.type === 'VERSION_READY') {
        this.showAppUpdateToast();
      }
    });
  }

  loadAll(): Promise<void> {
    return lastValueFrom(
      this.localStorageService.load().pipe(
        switchMap(() => this.categoriesService.update()),
        concatMap(() => this.conditionallyLoadCurrentUser())
      )
    );
  }

  private conditionallyLoadCurrentUser(): Observable<void> {
    if (this.localStorageService.getCurrentUserId() && this.localStorageService.getToken()) {
      this.notificationsService.start();
      return this.usersService.load().pipe(concatMap(() => this.bookmarksStateService.loadAll()));
    } else {
      return of(undefined);
    }
  }

  private async showAppUpdateToast() {
    const toast = await this.toastController.create({
      message: 'New Version available',
      position: 'bottom',
      buttons: [
        {
          text: 'Ignore',
          role: 'cancel'
        },
        {
          text: 'Update',
          role: 'update',
          handler: () => document.location.reload()
        }
      ]
    });
    await toast.present();
  }
}
