<footer>
  <ion-row>
    <ion-col class="ion-padding navigation-bottom">
      <a class="links" routerLink="/settings/general">About</a>
      <a class="links ion-hide-sm-down" routerLink="/settings/terms">Terms and Conditions</a>
      <a class="links ion-hide-md-down" routerLink="/settings/privacy">Privacy Policy</a>
    </ion-col>
    <ion-col class="ion-padding ion-text-right" size="auto">© 2017 - {{ currentYear }} ViaMondo</ion-col>
  </ion-row>
</footer>
