import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AllBookmarksResponse, BackendService } from 'viamondo-core/api';

import { MinimalPlace } from '../models/minimal-place';
import { MinimalTrip } from '../models/minimal-trip';

@Injectable({
  providedIn: 'root'
})
export class BookmarksStateService {
  trips: MinimalTrip[] = [];
  places: MinimalPlace[] = [];
  onChanges = new Subject<void>();

  constructor(private backendService: BackendService) {}

  isBookmarked(elementType: string, elementId: string): boolean {
    const bookmark = this[elementType].find((element: MinimalTrip | MinimalPlace) => element._id === elementId);
    return bookmark ? true : false;
  }

  loadAll(): Observable<void> {
    return this.backendService.getAllBookmarks().pipe(
      tap(result => this.mapResults(result)),
      map(() => undefined)
    );
  }

  clear(): void {
    this.trips = [];
    this.places = [];
    this.onChanges.next(undefined);
  }

  private mapResults(result: AllBookmarksResponse): void {
    this.trips = result.data.trips
      .map(t => MinimalTrip.fromBackend(t))
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    this.places = result.data.places
      .map(p => MinimalPlace.fromBackend(p))
      .sort((a, b) => {
        if (a.venue.name < b.venue.name) {
          return -1;
        }
        if (a.venue.name > b.venue.name) {
          return 1;
        }
        return 0;
      });
    this.onChanges.next(undefined);
  }
}
