import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SocialUser, UsersService } from 'viamondo-core/data';

@Component({
  selector: 'app-likes-modal',
  templateUrl: './likes.modal.html',
  styleUrls: ['./likes.modal.scss']
})
export class LikesModal {
  users: SocialUser[] = [];
  loading = true;

  @Input()
  set likesList(value: string[]) {
    this.usersService.identifyUsers(value).subscribe(users => {
      this.users = users;
      this.loading = false;
    });
  }

  constructor(private modalController: ModalController, private usersService: UsersService) {}

  close(): void {
    this.modalController.dismiss();
  }
}
