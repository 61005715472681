<ion-content (click)="hide()">
  <ion-button color="light" fill="clear" class="close-button">
    <ion-icon slot="icon-only" name="close"></ion-icon>
  </ion-button>
  <swiper [pagination]="true" [initialSlide]="index">
    <ng-template swiperSlide *ngFor="let image of images">
      <img
        *ngIf="image"
        [images-src]="image"
        data-crop="limit"
        data-gravity="none"
        sizes="(min-width: 768px) 600px, 100vw"
        [images-srcset]="['200w', '400w', '600w', '800w', '1200w', '1600w', '1800w', '2000w']"
        loading="eager"
        fetchpriority="high"
      />
    </ng-template>
  </swiper>
</ion-content>
