import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { GeoLocation } from '../data/models/geo-location';

@Injectable({
  providedIn: 'root'
})
export class GeocodingService {
  private openCageConfig: any;

  constructor(private http: HttpClient) {
    this.openCageConfig = environment.openCageConfig;
  }

  search(searchTerm: string): Observable<GeoLocation[]> {
    return this.forwardOpenCage(searchTerm, true) as Observable<GeoLocation[]>;
  }

  reverse(latitude: number, longitude: number): Observable<GeoLocation> {
    return this.reverseOpenCage(latitude, longitude);
  }

  private forwardOpenCage(term: string, multiple: boolean): Observable<GeoLocation | GeoLocation[]> {
    const params = new HttpParams({
      fromObject: {
        key: this.openCageConfig.key,
        q: term,
        no_annotations: '1',
        language: 'en'
      }
    });

    return this.http
      .get(this.openCageConfig.url, { params })
      .pipe(map(response => (multiple ? this.convertToMultipleResults(response, term) : this.convertToSingleResult(response, term))));
  }

  private reverseOpenCage(lat: number, lng: number): Observable<GeoLocation> {
    const params = new HttpParams({
      fromObject: {
        key: this.openCageConfig.key,
        q: lat + ',' + lng,
        no_annotations: '1',
        language: 'en'
      }
    });

    return this.http.get(this.openCageConfig.url, { params }).pipe(map(results => this.convertToSingleResult(results)));
  }

  private convertToMultipleResults(response: any, searchTerm?: string): GeoLocation[] {
    if (response.status.code !== 200) {
      throw new Error(response.status.message);
    }
    if (response.total_results === 0) {
      return [];
    }
    return this.sortAndConvertResults(response, searchTerm);
  }

  private convertToSingleResult(response: any, searchTerm?: string): GeoLocation {
    if (response.status.code !== 200) {
      throw new Error(response.status.message);
    }
    if (response.total_results === 0) {
      throw new Error('No results');
    }
    return this.sortAndConvertResults(response, searchTerm)[0];
  }

  private sortAndConvertResults(response: any, searchTerm?: string): GeoLocation[] {
    let results = response.results;
    if (response.total_results > 1) {
      results = results
        .filter(result => this.isRelevantResultType(result.components._type))
        .sort((a, b) => this.assignValueToTypes(b.components._type) - this.assignValueToTypes(a.components._type));
    }
    return results
      .map(r => this.convertOpenCageToGeoLocation(r))
      .filter(location => location.city && location.country && this.containsTerm(location, searchTerm));
  }

  private containsTerm(location: GeoLocation, searchTerm?: string): boolean {
    if (!searchTerm) {
      return true;
    }

    let foundMatch = false;
    const terms = searchTerm.toLowerCase().split(/[\s,]+/);
    terms.forEach(t => {
      if (t.length === 0) {
        return;
      }

      if (location.city.toLowerCase().indexOf(t) !== -1) {
        foundMatch = true;
        return;
      }

      if (location.state && location.state.toLowerCase().indexOf(t) !== -1) {
        foundMatch = true;
        return;
      }

      if (location.country && location.country.toLowerCase().indexOf(t) !== -1) {
        foundMatch = true;
        return;
      }

      if (location.street && location.street.toLowerCase().indexOf(t) !== -1) {
        foundMatch = true;
        return;
      }
    });
    return foundMatch;
  }

  private isRelevantResultType(givenType: string): boolean {
    return ['river', 'stream', 'station'].indexOf(givenType) === -1;
  }

  private assignValueToTypes(givenType: string): number {
    const mapping = {
      city: 10,
      village: 9,
      town: 9,
      island: 8,
      state: 8,
      country: 7,
      region: 7,
      county: 6,
      postcode: 6,
      neighbourhood: 5,
      building: 2,
      road: 2,
      peak: 2,
      ficticious: -1
    };

    const val = mapping[givenType];
    return val === undefined ? 0 : val;
  }

  private convertOpenCageToGeoLocation(result: any): GeoLocation {
    const location = {
      street: result.components.road,
      city: '',
      state: result.components.state,
      country: result.components.country,
      country_code: result.components.country_code.toUpperCase(),
      lat: result.geometry.lat,
      lng: result.geometry.lng,
      formatted: result.formatted
    };

    if (result.components.city) {
      location.city = result.components.city;
    } else if (result.components.town) {
      location.city = result.components.town;
    } else if (result.components.village) {
      location.city = result.components.village;
    } else if (result.components.municipality) {
      location.city = result.components.municipality;
    } else if (result.components.nature_reserve) {
      location.city = result.components.nature_reserve;
    } else if (result.components.county) {
      location.city = result.components.county;
    }

    return location;
  }
}
