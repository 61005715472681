import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { LocalStorageService } from 'viamondo-core/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class NoSessionTokenGuard implements CanActivate {
  constructor(private localStorageService: LocalStorageService, private router: Router) {}

  canActivate(): boolean | UrlTree {
    if (!this.localStorageService.getToken()) {
      return true;
    } else {
      return this.router.createUrlTree(['/']);
    }
  }
}
