import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { ImagesModule } from '../images/images.module';
import { SharedModule } from '../shared/shared.module';

import { ConnectionStatsComponent } from './components/connection-stats/connection-stats.component';
import { HeaderComponent } from './components/header/header.component';
import { ItemComponent } from './components/item/item.component';
import { ProfilePicComponent } from './components/profile-pic/profile-pic.component';
import { ReactionsComponent } from './components/reactions/reactions.component';
import { SelectGroupDirective } from './directives/select-group.directive';
import { ConnectionsModal } from './modals/connections/connections.modal';
import { GroupSelectorModal } from './modals/group-selector/group-selector.modal';
import { LikesModal } from './modals/likes/likes.modal';
import { NotificationsModal } from './modals/notifications/notifications.modal';

const components = [ConnectionStatsComponent, HeaderComponent, ItemComponent, ProfilePicComponent, ReactionsComponent];

const modals = [ConnectionsModal, GroupSelectorModal, LikesModal, NotificationsModal];

const directives = [SelectGroupDirective];

@NgModule({
  imports: [IonicModule, CommonModule, SharedModule, ImagesModule, RouterModule, ScrollingModule],
  declarations: [...components, ...modals, ...directives],
  exports: [...components, ...directives]
})
export class UsersModule {}
