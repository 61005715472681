import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { IonicModule } from '@ionic/angular';

import { FullMapComponent } from './components/full-map/full-map.component';
import { MultiPointMapComponent } from './components/multi-point-map/multi-point-map.component';
import { SinglePointMapComponent } from './components/single-point-map/single-point-map.component';
import { OpenMapDirective } from './directives/open-map.directive';

@NgModule({
  imports: [CommonModule, IonicModule, LeafletModule, LeafletMarkerClusterModule],
  declarations: [OpenMapDirective, SinglePointMapComponent, MultiPointMapComponent, FullMapComponent],
  exports: [OpenMapDirective, SinglePointMapComponent, MultiPointMapComponent]
})
export class MapsModule {}
