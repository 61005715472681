<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Visible to ...</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="back()">
        <ion-icon name="checkmark" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list>
    <ion-radio-group [value]="mode" (ionChange)="modeChanged($event)">
      <ion-item>
        <ion-label class="ion-text-wrap">
          <h2>Nobody</h2>
          <p>Only you will be able to see this {{ item }}.</p>
        </ion-label>
        <ion-radio slot="end" value="private" (click)="selectAll(false)"></ion-radio>
      </ion-item>
      <ion-item>
        <ion-label class="ion-text-wrap">
          <h2>Everyone</h2>
          <p>Everyone will be able to see this {{ item }}.</p>
        </ion-label>
        <ion-radio slot="end" value="public" (click)="selectAll(false)"></ion-radio>
      </ion-item>
      <ion-item lines="none">
        <ion-label class="ion-text-wrap">
          <h2>Selected groups</h2>
          <p>Only people in the groups you select below will be able to see this {{ item }}.</p>
        </ion-label>
        <ion-radio slot="end" value="groups"></ion-radio>
      </ion-item>
      <div *ngIf="mode === 'groups'">
        <ion-item *ngFor="let name of GROUP_NAMES">
          <ion-label class="ion-text-uppercase">{{ name }}</ion-label>
          <ion-checkbox slot="start" [(ngModel)]="groups[name]"></ion-checkbox>
        </ion-item>
      </div>
    </ion-radio-group>
  </ion-list>
</ion-content>
