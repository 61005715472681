import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { SwiperModule } from 'swiper/angular';

import { ImagesModule } from '../images/images.module';
import { MapsModule } from '../maps/maps.module';
import { SharedModule } from '../shared/shared.module';
import { UsersModule } from '../users/users.module';

import { CategoryBubbleComponent } from './components/category-bubble/category-bubble.component';
import { ItemComponent } from './components/item/item.component';
import { RatingComponent } from './components/rating/rating.component';
import { TagsComponent } from './components/tags/tags.component';
import { TagsSelectorComponent } from './components/tags-selector/tags-selector.component';
import { VenueInfoDirective } from './directives/venue-info.directive';
import { SelectCityModal } from './modals/select-city/select-city.modal';
import { VenueInfoModal } from './modals/venue-info/venue-info.modal';

const components = [CategoryBubbleComponent, ItemComponent, RatingComponent, TagsComponent, TagsSelectorComponent, VenueInfoDirective];

const modals = [SelectCityModal, VenueInfoModal];

@NgModule({
  imports: [IonicModule, CommonModule, FormsModule, SharedModule, ImagesModule, UsersModule, MapsModule, RouterModule, SwiperModule],
  declarations: [...components, ...modals],
  exports: [...components]
})
export class PlacesModule {}
