import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import { SocialUser, UsersService } from 'viamondo-core/data';
import { isIonRefresherEvent } from 'viamondo-utils/ionic-type-guards';

@Component({
  selector: 'app-connections-modal',
  templateUrl: './connections.modal.html',
  styleUrls: ['./connections.modal.scss']
})
export class ConnectionsModal implements OnInit {
  @Input()
  userId: string;

  @Input()
  peopleType: 'following' | 'followers' | 'all';

  loading: boolean;
  people: ReplaySubject<SocialUser[]> = new ReplaySubject(1);

  searchTerm = '';
  private personSearch: Subscription;
  private all: SocialUser[] = [];
  private connections: {
    following: SocialUser[];
    followers: SocialUser[];
  } = { following: [], followers: [] };

  constructor(private modalCtrl: ModalController, private usersService: UsersService) {}

  ngOnInit(): void {
    this.loading = true;
    this.getAll()
      .pipe(
        concatMap(() => this.getConnections(this.userId)),
        tap(() => this.switchPeople(this.peopleType))
      )
      .subscribe(() => (this.loading = false));
  }

  setPeopleType(event: Event): void {
    if (!event || !this.isCustomEvent(event)) {
      throw new Error(`setPeopleType did not receive a custom event: ${event}`);
    }
    this.peopleType = event.detail.value;
    this.switchPeople(this.peopleType);
  }

  private switchPeople(peopleType: 'following' | 'followers' | 'all'): void {
    if (peopleType === 'following') {
      this.people.next(this.connections.following.filter(user => this.filterUser(user, this.searchTerm)));
    } else if (peopleType === 'followers') {
      this.people.next(this.connections.followers.filter(user => this.filterUser(user, this.searchTerm)));
    } else {
      this.people.next(this.all);
    }
  }

  private isCustomEvent(event: Event): event is CustomEvent {
    return 'detail' in event;
  }

  doRefresh(event: Event): void {
    if (!isIonRefresherEvent(event)) {
      throw new Error(`doRefresh did not receive a refresh event: ${event}`);
    }

    this.getAll()
      .pipe(
        concatMap(() => this.getConnections(this.userId)),
        tap(() => this.switchPeople(this.peopleType))
      )
      .subscribe(() => event.detail.complete());
  }

  back(): void {
    this.modalCtrl.dismiss();
  }

  filter(event: any): void {
    this.searchTerm = event.detail.value.toLowerCase();
    this.loading = true;
    if (this.peopleType === 'following') {
      this.people.next(this.connections.following.filter(user => this.filterUser(user, this.searchTerm)));
      this.loading = false;
    } else if (this.peopleType === 'followers') {
      this.people.next(this.connections.followers.filter(user => this.filterUser(user, this.searchTerm)));
      this.loading = false;
    }

    this.personSearch = this.getAll().subscribe(() => {
      if (this.peopleType === 'all') {
        this.people.next(this.all);
        this.loading = false;
      }
    });
  }

  private filterUser(user: SocialUser, filterTerm: string): boolean {
    if (filterTerm) {
      return user.firstName.toLowerCase().includes(filterTerm) || user.lastName.toLowerCase().includes(filterTerm);
    } else {
      return true;
    }
  }

  private getConnections(userId: string): Observable<void> {
    return this.usersService.getUserConnections(userId).pipe(
      tap(results => {
        this.connections = results;
      }),
      map(() => undefined)
    );
  }

  private getAll(): Observable<void> {
    if (this.personSearch) {
      this.personSearch.unsubscribe();
    }
    const observable = this.searchTerm ? this.usersService.search(this.searchTerm) : this.usersService.discover();
    return observable.pipe(
      tap(results => (this.all = results)),
      map(() => undefined)
    );
  }
}
