<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="end">
      <ion-button (click)="back()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-searchbar placeholder="Search People" (ionChange)="filter($event)"></ion-searchbar>
  </ion-toolbar>
  <ion-toolbar color="primary">
    <ion-segment (ionChange)="setPeopleType($event)" [value]="peopleType">
      <ion-segment-button value="all">
        <ion-label>Find</ion-label>
      </ion-segment-button>
      <ion-segment-button value="following">
        <ion-label>Following</ion-label>
      </ion-segment-button>
      <ion-segment-button value="followers">
        <ion-label>Followers</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-header>

<ion-content [scrollY]="false">
  <ion-refresher (ionRefresh)="doRefresh($event)" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <cdk-virtual-scroll-viewport itemSize="66.195" minBufferPx="900" maxBufferPx="1350" class="ion-content-scroll-host">
    <ion-list *ngIf="loading" class="ion-no-padding">
      <users-item *ngFor="let i of (12 | range)"></users-item>
    </ion-list>

    <ion-list *ngIf="!loading">
      <users-item *cdkVirtualFor="let user of people" [user]="user"></users-item>
    </ion-list>
  </cdk-virtual-scroll-viewport>
</ion-content>
