import { Component, Input } from '@angular/core';
import { MinimalUser } from 'viamondo-core/data';

@Component({
  selector: 'users-profile-pic',
  templateUrl: './profile-pic.component.html',
  styleUrls: ['./profile-pic.component.scss']
})
export class ProfilePicComponent {
  imageError = false;

  @Input()
  size: number;

  @Input()
  user: MinimalUser;

  onLoadError(): void {
    this.imageError = true;
  }
}
