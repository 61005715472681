import { CurrentUserTO } from '../../api';

import { ISOString } from './isostring';
import { User } from './user';

export class CurrentUser extends User {
  birthDate: ISOString;
  email: string;

  followers: any[];
  following: any[];

  static fromBackend(userTO: CurrentUserTO): CurrentUser {
    const user = new CurrentUser(userTO);
    user.birthDate = userTO.birthDate;
    user.email = userTO.email;
    user.following = userTO.following;
    user.followers = userTO.followers;
    return user;
  }

  updateValues(formValues: any): void {
    Object.assign(this, formValues);
  }

  getFollowingGroup(otherUserId: string): string {
    let group = '';
    this.following.forEach(u => {
      if (u.userId === otherUserId) {
        group = u.group;
        return;
      }
    });
    return group;
  }
}
