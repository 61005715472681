import { Component, Input } from '@angular/core';
import { MinimalTrip } from 'viamondo-core/data';

@Component({
  selector: 'trips-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent {
  @Input()
  trip: MinimalTrip;

  @Input()
  usage = 'default';
}
