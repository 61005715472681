import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { User } from 'viamondo-core/data';

import { ConnectionsModal } from '../../modals/connections/connections.modal';

@Component({
  selector: 'users-connection-stats',
  templateUrl: './connection-stats.component.html',
  styleUrls: ['./connection-stats.component.scss']
})
export class ConnectionStatsComponent {
  @Input()
  user: User;

  constructor(private modalController: ModalController) {}

  async viewPeople(peopleType: string): Promise<void> {
    const modal = await this.modalController.create({
      component: ConnectionsModal,
      componentProps: {
        userId: this.user._id,
        peopleType
      }
    });
    // TODO: Reload user on closing of modal to make sure stats are up-to-date
    return await modal.present();
  }
}
