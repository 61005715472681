import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { environment } from 'environments/environment';
import { SwiperModule } from 'swiper/angular';

import { ListComponent } from './components/list/list.component';
import { ListInputComponent } from './components/list-input/list-input.component';
import { ImageDirective } from './directives/image.directive';
import { ShowImagesDirective } from './directives/show-images.directive';
import { ImageModal } from './modals/image/image.modal';
import { CloudinaryService } from './services/cloudinary.service';

@NgModule({
  imports: [CommonModule, IonicModule, SwiperModule],
  declarations: [ListComponent, ListInputComponent, ShowImagesDirective, ImageDirective, ImageModal],
  exports: [ListComponent, ListInputComponent, ShowImagesDirective, ImageDirective],
  providers: [
    {
      provide: CloudinaryService,
      useFactory: () =>
        new CloudinaryService(`${environment.cloudinaryConfig.protocol}//res.cloudinary.com/${environment.cloudinaryConfig.cloud_name}`)
    }
  ]
})
export class ImagesModule {}
