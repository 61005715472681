import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Privacy } from 'viamondo-core/data';

import { PrivacyModalComponent } from '../privacy-modal/privacy-modal.component';

@Component({
  selector: 'forms-privacy-input',
  templateUrl: './privacy-input.component.html',
  styleUrls: ['./privacy-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PrivacyInputComponent),
      multi: true
    }
  ]
})
export class PrivacyInputComponent implements ControlValueAccessor {
  @Input()
  element: string;

  @Input()
  errors: any;

  privacy: Privacy;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  propagateChange = (_: Privacy): void => undefined;

  constructor(private modalCtrl: ModalController) {}

  get invalid(): boolean {
    return this.errors && Object.keys(this.errors).length > 0;
  }

  async changePrivacy(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: PrivacyModalComponent,
      componentProps: {
        privacy: this.privacy,
        item: this.element
      },
      backdropDismiss: false
    });
    modal.onDidDismiss().then(event => {
      if (event.data) {
        this.propagateChange(event.data);
        this.privacy = event.data;
      }
    });
    return await modal.present();
  }

  writeValue(value: Privacy): void {
    if (value !== undefined) {
      this.privacy = value;
    }
  }

  registerOnChange(fn: () => any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(): void {}
}
