<div
  *ngIf="mapConfig"
  [id]="id"
  class="map"
  leaflet
  [leafletOptions]="mapConfig.options"
  (leafletMapReady)="onMapReady($event)"
  [leafletMarkerCluster]="clustered ? mapConfig.layer : []"
  [leafletMarkerClusterOptions]="mapConfig.markerClusterOptions"
>
  <ng-container *ngIf="!clustered">
    <div *ngFor="let l of mapConfig.layer" [leafletLayer]="l"></div>
  </ng-container>
</div>

<div class="cluster-checkbox">
  <ion-checkbox id="clustered-checkbox" (ionChange)="toggleClustered()" [checked]="clusteredCheckbox"></ion-checkbox>
  <label id="clustered-checkbox-lbl" for="clustered-checkbox" (click)="(toggleClustered)">Cluster markers</label>
</div>
