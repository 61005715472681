import { Component, Input } from '@angular/core';
import { CategoriesService, CategoryInfo } from 'viamondo-core/places';

@Component({
  selector: 'places-category-bubble',
  templateUrl: './category-bubble.component.html',
  styleUrls: ['./category-bubble.component.scss']
})
export class CategoryBubbleComponent {
  info: CategoryInfo;

  @Input()
  set category(value: string) {
    if (!value || value === 'skeleton') {
      this.info = {
        name: 'skeleton',
        icon: 'spinner',
        color: 'rgba(0, 0, 0, .1)'
      } as CategoryInfo;
    } else {
      this.info = this.categoriesService.info(value);
    }
  }

  @Input()
  withName = false;

  constructor(private categoriesService: CategoriesService) {}
}
