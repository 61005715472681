import { Injectable } from '@angular/core';
import { Share } from '@capacitor/share';
import { AlertController } from '@ionic/angular';
import { environment } from 'environments/environment';
import { concatMap } from 'rxjs/operators';

import { BackendService } from '../../api';
import { Basic } from '../models/basic';
import { BasicTypes } from '../models/basic-types.enum';

import { BookmarksStateService } from './bookmarks-state.service';
import { UsersService } from './users.service';

@Injectable()
export class BasicService {
  constructor(
    protected alertController: AlertController,
    protected backendService: BackendService,
    protected usersService: UsersService,
    private bookmarksStateService: BookmarksStateService
  ) {}

  initialize(item: Basic): void {
    const user = this.usersService.getCurrentUser();
    item.isOwn = item.creator._id === user._id;
    item.isLiked = item.likes.indexOf(user._id) >= 0;
    item.isBookmarked = this.bookmarksStateService.isBookmarked(item.basicType, item._id);
  }

  toggleLike(item: Basic): void {
    const user = this.usersService.getCurrentUser();
    item.toggleLike(user._id);
    this.backendService.toggleLike(item.basicType, item._id).subscribe({
      error: error => {
        item.toggleLike(user._id);
        throw error;
      }
    });
  }

  protected isSlug(idOrSlug: string): boolean {
    return idOrSlug.indexOf('-') !== -1;
  }

  toggleBookmark(item: Basic): void {
    item.isBookmarked = !item.isBookmarked;
    this.backendService
      .toggleBookmark(item.basicType, item._id)
      .pipe(concatMap(() => this.bookmarksStateService.loadAll()))
      .subscribe({
        error: error => {
          item.isBookmarked = !item.isBookmarked;
          throw error;
        }
      });
  }

  async share(item: Basic): Promise<void> {
    // TODO: Store sharing configuration somewhere
    if (item.privacy.mode === 'public') {
      this.handleSharing(item);
    } else {
      const alert = await this.alertController.create({
        header: 'Privacy Warning',
        message: 'After sharing everyone who has the link will be able to see it!',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel'
          },
          {
            text: 'OK',
            handler: () => {
              this.handleSharing(item);
            }
          }
        ]
      });
      return await alert.present();
    }
  }

  private handleSharing(item: Basic): void {
    let linkPartial: string;
    switch (item.basicType) {
      case BasicTypes.PLACE:
        linkPartial = 'places';
        break;
      case BasicTypes.TRIP:
        linkPartial = 'trips';
        break;
      case BasicTypes.ROUTE:
        linkPartial = 'routes';
        break;
    }

    // TODO: Use slug instead?
    this.backendService.share(item.basicType, item._id).subscribe(() => {
      const url = `${environment.shareUrl}/${linkPartial}/${item._id}`;
      Share.canShare().then(canShare => {
        if (canShare.value) {
          Share.share({ url });
        } else {
          this.showShareLink(url);
        }
      });
    });
  }

  private async showShareLink(url: string): Promise<void> {
    const alert = await this.alertController.create({
      header: 'Share Link',
      message: `<p>Copy the URL below and share it with your friends through your preferred method (e-mail, messenger, social media):</p><span class="selected-text">${url}</span>`,
      buttons: ['OK']
    });
    return await alert.present();
  }
}
