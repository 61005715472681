<ion-header>
  <ion-toolbar [color]="venue?.category">
    <ion-buttons slot="start">
      <ion-button>
        <ion-icon src="/assets/fontawesome/solid/{{ category?.icon }}.svg" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ category?.name }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="ion-padding">
    <h3>{{ venue?.name }}</h3>
    <p><a *ngIf="venue?.url" [href]="venue?.url" target="_system">{{ venue?.url | removehttp }}</a></p>
    <p><a [routerLink]="['/', 'venues', venue?._id]" (click)="close()">Show all reviews for this place</a></p>
    <p><a [href]="locationUrl" target="_blank" rel="noopener">{{ venue?.location.formatted }}</a></p>
  </div>
  <app-single-point-map *ngIf="mapDataCreator" [mapDataCreator]="mapDataCreator"></app-single-point-map>
</ion-content>
