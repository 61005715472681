import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AuthorizationInterceptor } from './services/authorization.interceptor';
import { BackendService } from './services/backend.service';
import { ErrorInterceptor } from './services/error.interceptor';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    BackendService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ]
})
export class ApiModule {}
