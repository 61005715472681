import { Component, Input } from '@angular/core';
import { IonInfiniteScroll } from '@ionic/angular';
import { MinimalPlace, MinimalTrip } from 'viamondo-core/data';

@Component({
  selector: 'app-lazy-load-list',
  templateUrl: './lazy-load-list.component.html',
  styleUrls: ['./lazy-load-list.component.scss']
})
export class LazyLoadListComponent<T> {
  private endSlice: number;
  private sliceLength = 20;
  private allItems: T[];
  private displayedItems: T[];

  loading = true;
  loadMoreDisabled = true;
  isOnline = true; // TODO

  @Input()
  itemType: 'Trip' | 'Place';

  @Input()
  usage?: 'bookmarks' | 'default' = 'default';

  @Input()
  set items(values: T[]) {
    if (values) {
      this.allItems = values;
      this.endSlice = Math.min(this.sliceLength, values.length + 1);
      this.updateValues();
      this.loading = false;
    }
  }

  private get maxEndSlice(): number {
    return this.allItems.length + 1;
  }

  get displayedTrips(): MinimalTrip[] {
    if (this.itemType !== 'Trip') {
      throw new Error(`Cannot show trips if itemType is ${this.itemType}`);
    }
    return this.displayedItems as unknown as MinimalTrip[];
  }

  get displayedPlaces(): MinimalPlace[] {
    if (this.itemType !== 'Place') {
      throw new Error(`Cannot show places if itemType is ${this.itemType}`);
    }
    return this.displayedItems as unknown as MinimalPlace[];
  }

  loadMoreEnd(event: Event): void {
    const infiniteScroll = event.target as unknown as IonInfiniteScroll;
    this.endSlice = Math.min(this.endSlice + this.sliceLength, this.maxEndSlice);
    this.updateValues();
    infiniteScroll.complete();
  }

  private updateValues(): void {
    this.displayedItems = this.allItems.slice(0, this.endSlice);
    this.loadMoreDisabled = this.endSlice === this.maxEndSlice;
  }
}
