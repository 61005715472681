import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { LocalStorageService } from '../../local-storage.service';

import { BackendService } from './backend.service';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private localStorageService: LocalStorageService, private backendService: BackendService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.startsWith(environment.backendUrl) || this.withoutAuthorization(req)) {
      return next.handle(req);
    }

    // Inspired by https://itnext.io/angular-tutorial-implement-refresh-token-with-httpinterceptor-bfa27b966f57
    return next.handle(this.addAuthorizationHeader(req)).pipe(
      catchError((error: any) => {
        if (!this.isExpiredToken(error)) {
          // Re-throw error if it isn't an expired token
          return throwError(() => error);
        } else if (this.refreshTokenInProgress) {
          // Wait for refreshing to be done and then make the request again
          return this.refreshingSubject.pipe(
            filter(result => result !== true),
            take(1),
            switchMap(() => next.handle(this.addAuthorizationHeader(req)))
          );
        } else {
          // Refresh token and then make the call again
          this.refreshTokenInProgress = true;
          this.refreshingSubject.next(true);

          return this.refreshToken().pipe(
            switchMap(() => {
              this.refreshTokenInProgress = false;
              this.refreshingSubject.next(false);
              return next.handle(this.addAuthorizationHeader(req));
            }),
            catchError((refreshError: any) => {
              this.refreshTokenInProgress = false;
              this.backendService.logout();
              return throwError(() => refreshError);
            })
          );
        }
      })
    );
  }

  private withoutAuthorization(req: HttpRequest<any>): boolean {
    // eslint-disable-next-line max-len
    return (
      (req.method === 'POST' &&
        (req.url.endsWith('/users') || req.url.endsWith('/auth/login') || req.url.endsWith('/auth/refreshToken'))) ||
      (req.method === 'GET' && req.url.endsWith('/configuration/categories'))
    );
  }

  private addAuthorizationHeader(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.localStorageService.getToken()}`
      }
    });
  }

  private isExpiredToken(error: any): boolean {
    return (
      error instanceof HttpErrorResponse &&
      error.status === 401 &&
      error.error.message.code === 'invalid_token' &&
      error.error.message.message === 'jwt expired'
    );
  }

  private refreshToken(): Observable<any> {
    return this.backendService
      .refreshToken({
        oldToken: this.localStorageService.getToken()
      })
      .pipe(
        tap(response => {
          this.localStorageService.setToken(response.token);
          this.localStorageService.setCurrentUserId(response.user._id);
        })
      );
  }
}
