import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { GeoLocation, Venue } from '../data';

import { CategoriesService } from './categories.service';
import { VenuesStateService } from './venues-state.service';

@Injectable({
  providedIn: 'root'
})
export class FoursquareService {
  private foursquareConfig: any;
  private commonOptions: any;

  constructor(private http: HttpClient, private venueStateService: VenuesStateService, private categoriesService: CategoriesService) {
    this.foursquareConfig = environment.foursquareConfig;
    this.commonOptions = {
      client_id: this.foursquareConfig.clientId,
      client_secret: this.foursquareConfig.clientSecret,
      v: this.foursquareConfig.version,
      m: this.foursquareConfig.m,
      intent: 'checkin',
      limit: 30
    };
  }

  explore(coordinates?: number[], nearTerm?: string): Observable<Venue[]> {
    const exploreOptions = Object.assign(
      {
        radius: 1000
      },
      this.commonOptions
    );
    if (coordinates) {
      exploreOptions.ll = coordinates.join(',');
    } else if (nearTerm) {
      exploreOptions.near = nearTerm;
    } else {
      throw new Error('Please specify the location.');
    }

    return this.http
      .get(`${this.foursquareConfig.url}/venues/search`, {
        params: new HttpParams({ fromObject: exploreOptions }),
        headers: new HttpHeaders().set('Accept-Language', 'en')
      })
      .pipe(
        map(result => this.fixData(result)),
        tap(venues => this.venueStateService.storeVenues(venues))
      );
  }

  search(searchTerm: string, coordinates: number[], nearTerm?: string): Observable<Venue[]> {
    const searchOptions = Object.assign(
      {
        query: searchTerm,
        radius: 100000
      },
      this.commonOptions
    );

    if (coordinates) {
      searchOptions.ll = coordinates.join(',');
    } else if (nearTerm) {
      searchOptions.near = nearTerm;
    } else {
      throw new Error('Please specify the location.');
    }

    return this.http
      .get(`${this.foursquareConfig.url}/venues/search`, {
        params: new HttpParams({ fromObject: searchOptions }),
        headers: new HttpHeaders().set('Accept-Language', 'en')
      })
      .pipe(
        map(result => this.fixData(result)),
        tap(venues => this.venueStateService.storeVenues(venues))
      );
  }

  details(venueId: string): Observable<Venue> {
    return this.http
      .get(`${this.foursquareConfig.url}/venues/${venueId}`, {
        params: new HttpParams({ fromObject: this.commonOptions }),
        headers: new HttpHeaders().set('Accept-Language', 'en')
      })
      .pipe(map((result: any) => this.formatVenueResponse(result.response.venue)));
  }

  private fixData(result: any): any {
    return result.response.venues.map(v => this.formatVenueResponse(v));
  }

  private formatVenueResponse(venueResponse: any): Venue {
    let category;
    for (const c of venueResponse.categories) {
      if (c.primary) {
        category = this.categoriesService.label(c.id);
      }
    }
    const venue: Venue = {
      originalId: venueResponse.id,
      source: 'foursquare',
      name: venueResponse.name,
      url: venueResponse.url,
      canonicalUrl: venueResponse.canonicalUrl,
      category: category || 'other',
      location: this.fixLocationData(venueResponse.location),
      photo: venueResponse.bestPhoto ? venueResponse.bestPhoto.prefix + 'original' + venueResponse.bestPhoto.suffix : null
    };

    return venue;
  }

  private fixLocationData(fsLocation: any): GeoLocation {
    const formatted = fsLocation.formattedAddress ? fsLocation.formattedAddress.join(', ') : `${fsLocation.city}, ${fsLocation.country}`;
    return {
      street: fsLocation.address,
      city: fsLocation.city,
      state: fsLocation.state,
      country: fsLocation.country,
      country_code: fsLocation.cc,
      lat: fsLocation.lat,
      lng: fsLocation.lng,
      distance: fsLocation.distance,
      formatted
    };
  }
}
