import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, zip } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { BackendService, FeedItemTO, FollowingFeedResponse, PublicFeedResponse } from '../../api';

export enum FeedTypeEnum {
  FOLLOWING = 'following',
  PUBLIC = 'public'
}

@Injectable({
  providedIn: 'root'
})
export class FeedsStateService {
  private LIMIT = 15;
  private currentFeedType: FeedTypeEnum;
  private publicFeed: FeedItemTO[];
  private followingFeed: FeedItemTO[];
  private offsets: any;
  private reachedEnd: any;

  isLoading = new ReplaySubject<boolean>(1);
  feed = new ReplaySubject<FeedItemTO[]>(1);

  constructor(private backendService: BackendService) {
    this.currentFeedType = FeedTypeEnum.FOLLOWING;
    this.offsets = {
      [FeedTypeEnum.FOLLOWING]: 0,
      [FeedTypeEnum.PUBLIC]: undefined
    };

    this.reachedEnd = {
      [FeedTypeEnum.FOLLOWING]: false,
      [FeedTypeEnum.PUBLIC]: false
    };
  }

  load(): Observable<void> {
    this.isLoading.next(true);
    return zip(this.backendService.getPublicFeed(this.LIMIT), this.backendService.getFollowingFeed(this.LIMIT)).pipe(
      map(([publicFeedResponse, followingFeedResponse]) => {
        this.mapFeed(FeedTypeEnum.FOLLOWING, followingFeedResponse);
        this.mapFeed(FeedTypeEnum.PUBLIC, publicFeedResponse);
      }),
      tap(() => this.isLoading.next(false))
    );
  }

  reload(): void {
    this.load().subscribe();
  }

  loadMore(feedType: FeedTypeEnum): Observable<void> {
    if (feedType === FeedTypeEnum.PUBLIC) {
      return this.backendService
        .getPublicFeed(this.LIMIT, this.offsets[feedType])
        .pipe(map(response => this.mapFeed(feedType, response, true)));
    } else {
      return this.backendService
        .getFollowingFeed(this.LIMIT, this.offsets[feedType])
        .pipe(map(response => this.mapFeed(feedType, response, true)));
    }
  }

  changeFeed(feedType: FeedTypeEnum): void {
    this.currentFeedType = feedType;
    this.feed.next(feedType === FeedTypeEnum.FOLLOWING ? this.followingFeed : this.publicFeed);
  }

  clear(): void {
    this.publicFeed = [];
    this.followingFeed = [];
    this.feed.next([]);
  }

  getFeed(): Observable<FeedItemTO[]> {
    return this.feed.asObservable();
  }

  hasReachedEnd(feedType: FeedTypeEnum): boolean {
    return this.reachedEnd[feedType];
  }

  private mapFeed(feedType: FeedTypeEnum, feedResponse: FollowingFeedResponse | PublicFeedResponse, append: boolean = false): void {
    if (feedType === FeedTypeEnum.FOLLOWING) {
      this.followingFeed = append ? this.followingFeed.concat(feedResponse.data) : feedResponse.data;
    } else {
      this.publicFeed = append ? this.publicFeed.concat(feedResponse.data) : feedResponse.data;
    }
    this.offsets[feedType] = feedResponse.nextOffset;
    this.reachedEnd[feedType] = feedResponse.reachedEnd;
    this.feed.next(this.currentFeedType === FeedTypeEnum.FOLLOWING ? this.followingFeed : this.publicFeed);
  }
}
