import { MinimalPlaceTO, MinimalPlaceWithParticipantsTO } from 'viamondo-core/api';

import { GeoLocation } from './geo-location';
import { Image } from './image';
import { ISOString } from './isostring';
import { MinimalUser } from './minimal-user';

export class MinimalPlace {
  _id: string;
  startDate: ISOString;
  rating: number;
  coverImage?: Image;
  venue: {
    _id: string;
    name: string;
    category: string;
    location: GeoLocation;
  };
  creator?: MinimalUser;

  static fromBackend(backendPlace: MinimalPlaceTO | MinimalPlaceWithParticipantsTO): MinimalPlace {
    const place = new MinimalPlace();
    place._id = backendPlace._id;
    place.startDate = backendPlace.startDate;
    place.rating = backendPlace.rating;
    place.venue = backendPlace.venueId;
    place.coverImage = backendPlace.images.find(img => img.primary);

    if (MinimalPlace.isWithParticipants(backendPlace)) {
      place.creator = backendPlace.participants[0];
    }
    return place;
  }

  private static isWithParticipants(place: MinimalPlaceTO | MinimalPlaceWithParticipantsTO): place is MinimalPlaceWithParticipantsTO {
    return (place as MinimalPlaceWithParticipantsTO).participants !== undefined;
  }
}
