import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private activeSession: BehaviorSubject<boolean> = new BehaviorSubject(false);

  isLoggedIn(): Observable<boolean> {
    return this.activeSession.asObservable();
  }

  addAuthenticatedSession(): void {
    this.activeSession.next(true);
  }

  removeAuthenticatedSession(): void {
    this.activeSession.next(false);
  }
}
