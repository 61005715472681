export const environment = {
  production: true,
  backendUrl: '/api/v2',
  shareUrl: 'https://www.viamondoapp.com',
  openCageConfig: {
    url: 'https://api.opencagedata.com/geocode/v1/json',
    key: 'cd6902b4f858993d7757701dab3ee403'
  },
  foursquareConfig: {
    url: 'https://api.foursquare.com/v2',
    version: '20160127',
    m: 'foursquare',
    clientId: 'L42UEOW1WRDP1ZLXOC2VQKOC0JT1DL3E4RK2Z40NME5NBB1M',
    clientSecret: '1BDUS3NGZ212E0CRUL0Z02OWLVLFHLGAOETURRPWSP3HWJGJ'
  },
  mapboxConfig: {
    token: 'pk.eyJ1IjoidmlhbW9uZG8iLCJhIjoiY2l3ZTA1NWtrMDA4NDJ0cDRtZHhqOGV3ayJ9.nXJyg-DOcNGuZudQKy_QSw',
    url: 'https://api.mapbox.com/styles/v1/mapbox/streets-v10/tiles/256/{z}/{x}/{y}?access_token='
  },
  cloudinaryConfig: {
    cloud_name: 'viamondo',
    protocol: 'https:',
    upload_preset: 'gftaixvs'
  }
};
