import { Injectable } from '@angular/core';

import { Venue } from '../data';

@Injectable({
  providedIn: 'root'
})
export class VenuesStateService {
  private venueStorage: { [key: string]: Venue } = {};

  constructor() {}

  getVenueById(id: string): Venue {
    const backupVenue: Venue = {
      canonicalUrl: undefined,
      category: 'culture',
      location: {
        city: 'London',
        country: 'United States',
        country_code: 'US',
        distance: 148,
        formatted: 'London, OH 43140, United States',
        lat: 39.88625390533563,
        lng: -83.44997158667607,
        state: 'OH'
      },
      name: 'Madison Co Court House',
      originalId: '4cbca22d43ec6dcb732db231',
      photo: null,
      source: 'foursquare',
      url: undefined
    };

    return this.venueStorage[id] || backupVenue;
  }

  storeVenues(values: Venue[]): void {
    values.forEach(venue => {
      this.venueStorage[venue.originalId] = venue;
    });
  }

  storeCustomVenue(venue: Venue): void {
    this.venueStorage['custom'] = venue;
  }
}
