import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { BasicTypes } from '../../data/models/basic-types.enum';
import {
  ChangePasswordRequestBody,
  LoginRequestBody,
  RefreshTokendRequestBody,
  ResetPasswordRequestBody,
  SignupRequestBody
} from '../models/authentication-requests';
import { LoginResponse, RefreshTokenResponse, SignupResponse } from '../models/authentication-responses';
import { AllBookmarksResponse } from '../models/bookmarks-responses';
import { FollowingFeedResponse, PublicFeedResponse } from '../models/feeds-responses';
import { NotificationsResponse, NotificationsUnreadResponse } from '../models/notifications-responses';
import { SearchPlacesRequestBody, UnpopulatedPlace } from '../models/places-requests';
import {
  DiscoverPlacesResponse,
  DiscoverPlaceTO,
  PlaceDetailsResponse,
  PlacesSearchResponse,
  UserPlacesResponse,
  VenueDetailsResponse
} from '../models/places-responses';
import { RouteDetailsResponse } from '../models/routes-responses';
import {
  CurrentUserTO,
  DetailPlaceTO,
  FullOwnTripTO,
  FullTripTO,
  MinimalPlaceTO,
  MinimalTripTO,
  OwnDetailPlaceTO,
  RouteTO,
  UpdateUserTO,
  UserBioTO,
  UserProfileTO,
  VenueWithPlacesTO
} from '../models/shared';
import { SuccessResponse } from '../models/shared-responses';
import { UnpopulatedTrip } from '../models/trips-requests';
import { CurrentTripResponse, TripDetailsResponse, TripsResponse } from '../models/trips-responses';
import {
  DiscoverPeopleResponse,
  PeopleSearchResponse,
  StatisticsResponse,
  UserConnectionsResponse,
  UserResponse,
  UsersIdentifyResponse
} from '../models/users-responses';

@Injectable()
export class BackendService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.backendUrl;
  }

  // Common

  toggleLike(elementType: BasicTypes, elementId: string): Observable<SuccessResponse> {
    // TODO: Consider mapping basicTypes instead of using them directly in the URL
    return this.http.post<SuccessResponse>(`${this.baseUrl}/${elementType}/${elementId}`, {});
  }

  share(elementType: BasicTypes, elementId: string): Observable<SuccessResponse> {
    // TODO: Consider mapping basicTypes instead of using them directly in the URL
    return this.http.get<SuccessResponse>(`${this.baseUrl}/${elementType}/${elementId}/share`);
  }

  // Configuration

  categories(): Observable<any> {
    return this.http.get(`${this.baseUrl}/configuration/categories`);
  }

  // Authentication

  login(body: LoginRequestBody): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`${this.baseUrl}/auth/login`, body);
  }

  logout(): Observable<SuccessResponse> {
    return this.http.post<SuccessResponse>(`${this.baseUrl}/auth/logout`, {});
  }

  refreshToken(body: RefreshTokendRequestBody): Observable<RefreshTokenResponse> {
    return this.http.post<RefreshTokenResponse>(`${this.baseUrl}/auth/refreshToken`, body);
  }

  signup(body: SignupRequestBody): Observable<SignupResponse> {
    return this.http.post<SignupResponse>(`${this.baseUrl}/users`, body);
  }

  changePassword(body: ChangePasswordRequestBody): Observable<SuccessResponse> {
    return this.http.post<SuccessResponse>(`${this.baseUrl}/auth/change_password`, body);
  }

  resetPassword(body: ResetPasswordRequestBody): Observable<SuccessResponse> {
    return this.http.post<SuccessResponse>(`${this.baseUrl}/auth/forgot_password`, body);
  }

  // Bookmarks

  getAllBookmarks(): Observable<AllBookmarksResponse> {
    return this.http.get<AllBookmarksResponse>(this.baseUrl + '/bookmarks');
  }

  toggleBookmark(elementType: BasicTypes, elementId: string): Observable<void> {
    let entityType: string;
    switch (elementType) {
      case BasicTypes.PLACE:
        entityType = 'Place';
        break;
      case BasicTypes.TRIP:
        entityType = 'Trip';
        break;
      case BasicTypes.ROUTE:
        entityType = 'Route';
        break;
    }

    return this.http.post<void>(`${this.baseUrl}/bookmarks`, {
      entityType,
      entityId: elementId
    });
  }

  // Feeds

  getPublicFeed(limit: number, offset?: string): Observable<PublicFeedResponse> {
    const params = new HttpParams({
      fromObject: {
        limit: limit.toString(),
        offsetDate: offset !== undefined ? offset : ''
      }
    });

    return this.http.get<PublicFeedResponse>(this.baseUrl + '/public-feed', { params });
  }

  getFollowingFeed(limit: number, offset: number = 0): Observable<FollowingFeedResponse> {
    const params = new HttpParams({
      fromObject: {
        limit: limit.toString(),
        offset: offset.toString()
      }
    });

    return this.http.get<FollowingFeedResponse>(this.baseUrl + '/feed', { params });
  }

  // Notifications

  getNotifications(limit: number, offset: number): Observable<NotificationsResponse> {
    const params = new HttpParams({
      fromObject: {
        limit: limit.toString(),
        offset: offset.toString()
      }
    });

    return this.http.get<NotificationsResponse>(`${this.baseUrl}/notifications`, { params });
  }

  getUnreadNotificationsCount(): Observable<number> {
    return this.http.get<NotificationsUnreadResponse>(`${this.baseUrl}/notifications/unread`).pipe(map(response => response.data));
  }

  // Places

  getPlacesByUser(userId: string): Observable<MinimalPlaceTO[]> {
    return this.http.get<UserPlacesResponse>(`${this.baseUrl}/users/${userId}/places/`).pipe(map(response => response.data));
  }

  getPlaceDetails(placeId: string): Observable<DetailPlaceTO | OwnDetailPlaceTO> {
    return this.http.get<PlaceDetailsResponse>(`${this.baseUrl}/places/${placeId}`).pipe(map(response => response.data));
  }

  getPlaceDetailsBySlug(placeSlug: string): Observable<DetailPlaceTO | OwnDetailPlaceTO> {
    return this.http.get<PlaceDetailsResponse>(`${this.baseUrl}/places/by_slug/${placeSlug}`).pipe(map(response => response.data));
  }

  getVenueDetails(venueId: string): Observable<VenueWithPlacesTO> {
    return this.http.get<VenueDetailsResponse>(`${this.baseUrl}/venues/${venueId}`).pipe(map(response => response.data));
  }

  getVenueDetailsBySlug(venueSlug: string): Observable<VenueWithPlacesTO> {
    return this.http.get<VenueDetailsResponse>(`${this.baseUrl}/venues/by_slug/${venueSlug}`).pipe(map(response => response.data));
  }

  createPlace(placeTO: UnpopulatedPlace): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/places/`, placeTO);
  }

  savePlace(placeId: string, placeTO: UnpopulatedPlace): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/places/${placeId}`, placeTO);
  }

  deletePlace(placeId: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/places/${placeId}`);
  }

  discoverPlaces(limit?: number): Observable<DiscoverPlaceTO[]> {
    return this.http
      .get<DiscoverPlacesResponse>(`${this.baseUrl}/discover/places/`, { params: { limit } })
      .pipe(map(response => response.data));
  }

  searchPlaces(body: SearchPlacesRequestBody): Observable<PlacesSearchResponse> {
    return this.http.post<PlacesSearchResponse>(`${this.baseUrl}/search/places`, body);
  }

  // Routes

  getRouteDetails(routeId: string): Observable<RouteTO> {
    return this.http.get<RouteDetailsResponse>(`${this.baseUrl}/routes/${routeId}`).pipe(map(response => response.data));
  }

  // Trips

  getTripsByUser(userId: string): Observable<MinimalTripTO[]> {
    return this.http.get<TripsResponse>(`${this.baseUrl}/users/${userId}/trips/`).pipe(map(response => response.data));
  }

  getTripDetails(tripId: string): Observable<FullTripTO | FullOwnTripTO> {
    return this.http.get<TripDetailsResponse>(`${this.baseUrl}/trips/${tripId}`).pipe(map(response => response.data));
  }

  getTripDetailsBySlug(tripSlug: string): Observable<FullTripTO | FullOwnTripTO> {
    return this.http.get<TripDetailsResponse>(`${this.baseUrl}/trips/by_slug/${tripSlug}`).pipe(map(response => response.data));
  }

  getCurrentTrip(): Observable<MinimalTripTO> {
    return this.http.get<CurrentTripResponse>(`${this.baseUrl}/trips/current`).pipe(map(response => response.data));
  }

  createTrip(tripTO: UnpopulatedTrip): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/trips/`, tripTO);
  }

  saveTrip(tripId: string, tripTO: UnpopulatedTrip): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/trips/${tripId}`, tripTO);
  }

  deleteTrip(tripId: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/trips/${tripId}`);
  }

  // Users

  getUser(userId: string): Observable<CurrentUserTO | UserProfileTO> {
    return this.http.get<UserResponse>(`${this.baseUrl}/users/${userId}`).pipe(map(response => response.data));
  }

  getUserBySlug(userSlug: string): Observable<CurrentUserTO | UserProfileTO> {
    return this.http.get<UserResponse>(`${this.baseUrl}/users/by_slug/${userSlug}`).pipe(map(response => response.data));
  }

  getStatistics(userId: string): Observable<StatisticsResponse> {
    return this.http.get<StatisticsResponse>(`${this.baseUrl}/users/${userId}/statistics`);
  }

  saveUser(userTO: UpdateUserTO): Observable<SuccessResponse> {
    return this.http.put<SuccessResponse>(`${this.baseUrl}/users`, userTO);
  }

  discoverPeople(): Observable<DiscoverPeopleResponse> {
    return this.http.get<DiscoverPeopleResponse>(`${this.baseUrl}/discover/people`);
  }

  getUserConnections(userId: string): Observable<UserConnectionsResponse> {
    return this.http.get<UserConnectionsResponse>(`${this.baseUrl}/users/${userId}/connections`);
  }

  searchPeople(searchTerm: string): Observable<PeopleSearchResponse> {
    return this.http.post<PeopleSearchResponse>(`${this.baseUrl}/search/people`, { searchTerm });
  }

  followUser(userId: string, group: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/users/${userId}/follow`, { group });
  }

  unfollowUser(userId: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/users/${userId}/follow`);
  }

  blockUser(userId: string): Observable<SuccessResponse> {
    return this.http.post<SuccessResponse>(`${this.baseUrl}/users/${userId}/block`, {});
  }

  unblockUser(userId: string): Observable<SuccessResponse> {
    return this.http.delete<SuccessResponse>(`${this.baseUrl}/users/${userId}/block`);
  }

  identifyUsers(users: string[]): Observable<UserBioTO[]> {
    return this.http.post<UsersIdentifyResponse>(`${this.baseUrl}/users/identify`, { users }).pipe(map(response => response.data));
  }
}
