import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, Subscription } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { BackendService } from 'viamondo-core/api';

import { Notification } from '../models/notification';

import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private LIMIT = 20;
  private offset = 0;
  private unreadChanges = new BehaviorSubject<number>(0);
  private notificationPoller: Subscription;

  constructor(private usersService: UsersService, private backendService: BackendService) {}

  start(): void {
    this.notificationPoller = interval(50000)
      .pipe(
        mergeMap(() => this.backendService.getUnreadNotificationsCount()),
        tap(count => this.unreadChanges.next(count))
      )
      .subscribe();
  }

  stop(): void {
    if (this.notificationPoller) {
      this.notificationPoller.unsubscribe();
    }
  }

  getNotifications(fromStart: boolean = true): Observable<Notification[]> {
    if (fromStart) {
      this.offset = 0;
    }
    const currentUser = this.usersService.getCurrentUser();

    return this.backendService.getNotifications(this.LIMIT, this.offset).pipe(
      tap(response => (this.offset = response.nextOffset)),
      map(response => {
        const notifications = response.data.map(n => new Notification(n));
        notifications.forEach(n => (n.user.group = currentUser.getFollowingGroup(n.user._id)));
        return notifications;
      })
    );
  }

  getUnreadChanges(): Observable<number> {
    return this.unreadChanges.asObservable();
  }
}
