<ion-item *ngIf="user" (click)="showUser()">
  <users-profile-pic [user]="user" [size]="40" slot="start"></users-profile-pic>
  <ion-label>
    <h2>{{ user.firstName }} {{ user.lastName }}</h2>
    <p>
      <span *ngIf="user.group" class="ion-text-uppercase">{{ user.group }}</span>
      <span *ngIf="user.group && user.location"> - </span>
      <span *ngIf="user.location">{{ user.location | location }}</span>
    </p>
  </ion-label>
  <ion-button slot="end" *ngIf="showGroupButton" color="primary" [fill]="user.group ? 'solid' : 'outline'" [users-select-group]="user">
    <ion-icon name="person-add" slot="icon-only"></ion-icon>
  </ion-button>
</ion-item>
<ion-item *ngIf="!user">
  <users-profile-pic [size]="40" slot="start"></users-profile-pic>
  <ion-label>
    <ion-skeleton-text width="100px"></ion-skeleton-text>
    <ion-skeleton-text width="50px"></ion-skeleton-text>
  </ion-label>
  <ion-button slot="end" color="medium" fill="solid">
    <ion-icon name="person-add" slot="icon-only"></ion-icon>
  </ion-button>
</ion-item>
