import { differenceInMinutes, parseISO } from 'date-fns';

import { DetailPlaceTO, NewsItemPlaceTO, OwnDetailPlaceTO } from '../../api';

import { Basic } from './basic';
import { BasicTypes } from './basic-types.enum';
import { ISOString } from './isostring';
import { MinimalTrip } from './minimal-trip';
import { Tag } from './tag';
import { Venue } from './venue';

export class Place extends Basic {
  // id, creator, privacy, images & likes are defined by basic
  rating: number;
  comment: string;
  tags: Tag[];
  startDate: ISOString;
  endDate?: ISOString;
  numDays: number;

  venue: Venue;
  trip: MinimalTrip;

  constructor() {
    super(BasicTypes.PLACE);
    this.tags = [];
  }

  static fromVenue(venue: Venue): Place {
    const place = new Place();
    place.setInitialPrivacy();
    place.venue = venue;
    place.images = [];
    return place;
  }

  static fromBackend(backendPlace: DetailPlaceTO | OwnDetailPlaceTO | NewsItemPlaceTO): Place {
    const place = new Place();
    place.setCreator();
    place.setCommon(backendPlace._id, backendPlace.images);
    place.setReactions(backendPlace.likes);

    place.rating = backendPlace.rating;
    place.comment = backendPlace.comment;
    place.tags = backendPlace.tags || [];
    place.startDate = backendPlace.startDate;
    place.endDate = backendPlace.endDate;
    place.numDays = Basic.dateDiffInDays(place.startDate, place.endDate);
    // if the difference is less than a minute, set it to be the same
    if (differenceInMinutes(parseISO(place.endDate), parseISO(place.startDate)) <= 1) {
      place.endDate = undefined;
    }
    place.venue = backendPlace.venueId;
    if (Place.isDetailPlace(backendPlace)) {
      place.setCreator(backendPlace.participants[0]);
      if (backendPlace.tripId) {
        place.trip = MinimalTrip.fromBackend(backendPlace.tripId);
      }
    }
    if (Place.isOwnPlace(backendPlace)) {
      place.privacy = backendPlace.privacy;
    }
    return place;
  }

  private static isDetailPlace(place: DetailPlaceTO | OwnDetailPlaceTO | NewsItemPlaceTO): place is DetailPlaceTO {
    return (place as DetailPlaceTO).participants !== undefined;
  }

  private static isOwnPlace(place: DetailPlaceTO | OwnDetailPlaceTO | NewsItemPlaceTO): place is OwnDetailPlaceTO {
    return (place as OwnDetailPlaceTO).privacy !== undefined;
  }
}
