export class TransparentHeader {
  showToolbar = false;

  onScroll(event: Event): void {
    if (!event || !this.isCustomEvent(event)) {
      throw new Error(`onScroll did not receive a custom event: ${event}`);
    }

    if (event.detail.scrollTop) {
      const scrollTop = event.detail.scrollTop;
      this.showToolbar = scrollTop >= 100;
    }
  }

  isCustomEvent(event: Event): event is CustomEvent {
    return 'detail' in event;
  }
}
