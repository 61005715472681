import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, Observable } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';

import { BackendService, CurrentUserTO } from '../../api';
import { LocalStorageService } from '../../local-storage.service';
import { CurrentUser } from '../models/current-user';
import { SocialUser } from '../models/social-user';
import { User } from '../models/user';

import { ImageService } from './image.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private currentUser: CurrentUser;

  constructor(
    private backendService: BackendService,
    private localStorageService: LocalStorageService,
    private spinnerService: NgxSpinnerService,
    private imageService: ImageService
  ) {}

  load(): Observable<void> {
    return this.backendService.getUser(this.localStorageService.getCurrentUserId()).pipe(
      tap(userTO => this.setCurrentUser(userTO as CurrentUserTO)),
      map(() => undefined)
    );
  }

  getCurrentUser(): CurrentUser {
    return this.currentUser;
  }

  setCurrentUser(userTO: CurrentUserTO): void {
    this.currentUser = CurrentUser.fromBackend(userTO);
  }

  saveCurrentUser(): Observable<void> {
    this.spinnerService.show();
    return forkJoin({
      profileCover: this.imageService.uploadImages('profileCovers', [this.currentUser.profileCover]),
      profileImage: this.imageService.uploadImages('profileImages', [this.currentUser.profileImage])
    }).pipe(
      map(images => {
        this.currentUser.profileCover = images.profileCover[0];
        this.currentUser.profileImage = images.profileImage[0];
      }),
      concatMap(() => this.backendService.saveUser(this.currentUser)),
      map(() => undefined)
    );
  }

  clear(): void {
    this.currentUser = undefined;
  }

  getUser(userId: string): Observable<User> {
    const backendCall = userId.indexOf('-') !== -1 ? this.backendService.getUserBySlug(userId) : this.backendService.getUser(userId);
    return backendCall.pipe(map(userTO => new User(userTO, this.currentUser.getFollowingGroup(userTO._id))));
  }

  follow(user: SocialUser, group: string): Observable<void> {
    user.group = group;
    return this.backendService.followUser(user._id, group).pipe(map(() => undefined));
  }

  unfollow(user: SocialUser): Observable<void> {
    user.group = undefined;
    return this.backendService.unfollowUser(user._id).pipe(map(() => undefined));
  }

  block(user: SocialUser): Observable<void> {
    user.group = undefined;
    return this.backendService.blockUser(user._id).pipe(map(() => undefined));
  }

  unblock(user: SocialUser): Observable<void> {
    return this.backendService.unblockUser(user._id).pipe(map(() => undefined));
  }

  search(searchTerm: string): Observable<SocialUser[]> {
    return this.backendService
      .searchPeople(searchTerm)
      .pipe(map(results => results.data.map(u => new SocialUser(u, this.currentUser.getFollowingGroup(u._id)))));
  }

  discover(): Observable<SocialUser[]> {
    return this.backendService
      .discoverPeople()
      .pipe(map(results => results.data.map(u => new SocialUser(u, this.currentUser.getFollowingGroup(u._id)))));
  }

  getUserConnections(userId: string): Observable<{ following: SocialUser[]; followers: SocialUser[] }> {
    return this.backendService.getUserConnections(userId).pipe(
      map(response => ({
        following: this.convertAndSort(response.data.following),
        followers: this.convertAndSort(response.data.followers)
      }))
    );
  }

  identifyUsers(userList: string[]): Observable<SocialUser[]> {
    return this.backendService
      .identifyUsers(userList)
      .pipe(map(userTOs => userTOs.map(userTO => new SocialUser(userTO, this.currentUser.getFollowingGroup(userTO._id)))));
  }

  private convertAndSort(values: any): SocialUser[] {
    return values
      .map(value => new SocialUser(value.userId, this.currentUser.getFollowingGroup(value.userId._id)))
      .sort((a: SocialUser, b: SocialUser) => {
        const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
        const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
  }
}
