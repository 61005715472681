import { Component, Input } from '@angular/core';
import { Tag } from 'viamondo-core/data';
import { CategoriesService, CategoryInfo } from 'viamondo-core/places';

@Component({
  selector: 'places-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent {
  info: CategoryInfo;
  allTags: string[] = [];

  @Input()
  set tags(value: Tag[]) {
    this.allTags = [];
    for (const t of value) {
      this.allTags = this.allTags.concat(t.values);
    }
  }

  @Input()
  set category(value: string) {
    this.info = this.categoriesService.info(value);
  }

  constructor(private categoriesService: CategoriesService) {}
}
