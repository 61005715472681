import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { differenceInHours } from 'date-fns';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { BackendService } from '../api';
import { CategoryTag } from '../data';
import { LocalStorageService } from '../local-storage.service';

import { CategoriesInfo, CategoryInfo, CategoryMapping } from './categories.types';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  private ourCategories: CategoriesInfo;
  private categoryMapping: CategoryMapping;

  constructor(private http: HttpClient, private backendService: BackendService, private localStorageService: LocalStorageService) {
    this.http.get<CategoriesInfo>('./assets/data/categories-info.json').subscribe(data => (this.ourCategories = data));
    this.http.get<CategoryMapping>('./assets/data/categories-mapping.json').subscribe(data => (this.categoryMapping = data));
  }

  update(): Observable<void> {
    const lastUpdatedTime = this.localStorageService.getCategoriesLastUpdatedTime();
    if (!lastUpdatedTime || differenceInHours(lastUpdatedTime, new Date()) > 24) {
      return this.backendService.categories().pipe(
        tap(response => {
          this.ourCategories = response;
          this.localStorageService.setCategories(response);
        }),
        map(() => undefined),
        catchError(error => {
          console.error('Error returning categories: ', error);
          return of(undefined);
        })
      );
    } else {
      this.ourCategories = this.localStorageService.getCategories();
      return of(undefined);
    }
  }

  label(foursquareCategory: string): string {
    const mm = this.categoryMapping[foursquareCategory];
    if (mm === undefined) {
      return 'other';
    } else {
      return mm.vmCategory;
    }
  }

  info(ourLabel: string): CategoryInfo {
    return ourLabel ? this.ourCategories[ourLabel] : this.ourCategories.other;
  }

  getAllNames(): string[] {
    return Object.keys(this.ourCategories);
  }

  getCategorySelectOptions(): any {
    return this.getAllNames().map(key => ({ name: this.ourCategories[key].name, value: key }));
  }

  searchStarter(): any {
    const starter = {};
    for (const key of this.getAllNames()) {
      const value = this.ourCategories[key];
      starter[key] = {
        selected: false,
        icon: value.icon.replace('icon-', ''),
        color: value.color,
        textColor: value.color,
        backgroundColor: 'white'
      };
    }
    return starter;
  }

  availableTags(category: string): CategoryTag[] {
    if (category === undefined) {
      return [];
    }

    const categoryInfo = this.ourCategories[category];
    if (categoryInfo) {
      return categoryInfo.tagOrder.map(tagName => ({
        name: tagName,
        options: categoryInfo.tags[tagName]
      }));
    } else {
      return [];
    }
  }
}
