import { NgModule } from '@angular/core';
import { RouterModule, Routes, TitleStrategy } from '@angular/router';

import { CustomTitleStrategy } from './custom-title-strategy';
import { NotFoundComponent } from './not-found/not-found.component';
import { NoSessionTokenGuard } from './session/guards/no-session-token.guard';
import { SessionTokenGuard } from './session/guards/session-token.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'tabs',
    pathMatch: 'full'
  },
  {
    path: 'session',
    canActivate: [NoSessionTokenGuard],
    loadChildren: () => import('./session/session.module').then(m => m.SessionModule)
  },
  {
    path: 'tabs',
    canActivate: [SessionTokenGuard],
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'check-in', // old link from previous web app
    redirectTo: 'places'
  },
  {
    path: 'places',
    loadChildren: () => import('./places/places.module').then(m => m.PlacesPagesModule)
  },
  {
    path: 'place', // old link from previous web app
    redirectTo: 'venues'
  },
  {
    path: 'venues',
    loadChildren: () => import('./venues/venues.module').then(m => m.VenuesPagesModule)
  },
  {
    path: 'trip', // old link from previous web app
    redirectTo: 'trips'
  },
  {
    path: 'trips',
    loadChildren: () => import('./trips/trips.module').then(m => m.TripsPagesModule)
  },
  {
    path: 'users',
    canActivate: [SessionTokenGuard],
    loadChildren: () => import('./users/users.module').then(m => m.UsersPagesModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsPageModule)
  },
  {
    path: '**',
    title: 'Not Found',
    component: NotFoundComponent
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, enableTracing: false })],
  exports: [RouterModule],
  providers: [{ provide: TitleStrategy, useClass: CustomTitleStrategy }]
})
export class AppRoutingModule {}
