import { Directive, HostListener, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SocialUser } from 'viamondo-core/data';

import { GroupSelectorModal } from '../modals/group-selector/group-selector.modal';

@Directive({
  selector: '[users-select-group]'
})
export class SelectGroupDirective {
  @Input('users-select-group')
  user: SocialUser;

  constructor(private modalController: ModalController) {}

  @HostListener('click', ['$event'])
  async onClick(event: any): Promise<void> {
    event.stopPropagation();
    const modal = await this.modalController.create({
      component: GroupSelectorModal,
      componentProps: {
        otherUser: this.user
      }
    });
    return await modal.present();
  }
}
