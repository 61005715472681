// Copied from https://github.com/rsaenen/ngx-custom-validators/blob/master/src/app/not-equal-to/validator.ts

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const NotEqualToValidator = (notEqualControl: AbstractControl): ValidatorFn => {
  let subscribe = false;
  return (control: AbstractControl): ValidationErrors | null => {
    if (!subscribe) {
      subscribe = true;
      notEqualControl.valueChanges.subscribe(() => {
        control.updateValueAndValidity();
      });
    }

    const v: string = control.value;

    if (notEqualControl.value == null && v == null) {
      return null;
    }

    return notEqualControl.value !== v ? null : { notEqualTo: { control: notEqualControl, value: notEqualControl.value } };
  };
};
