<ion-header>
  <ion-toolbar color="primary">
    <ion-title>{{ text }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <app-multi-point-map *ngIf="!isSinglePointMap && multiMapCreator" [mapDataCreator]="multiMapCreator"></app-multi-point-map>
  <app-single-point-map *ngIf="isSinglePointMap && singleMapCreator" [mapDataCreator]="singleMapCreator"></app-single-point-map>
</ion-content>
