import { Component, Input } from '@angular/core';
import { Place, PlacesService, Trip, TripsService } from 'viamondo-core/data';

@Component({
  selector: 'app-bookmark-button',
  templateUrl: './bookmark-button.component.html',
  styleUrls: ['./bookmark-button.component.scss']
})
export class BookmarkButtonComponent {
  @Input()
  item: Trip | Place;

  @Input()
  withText = false;

  constructor(private tripsService: TripsService, private placesService: PlacesService) {}

  toggle(): void {
    if (this.item instanceof Trip) {
      this.tripsService.toggleBookmark(this.item);
    } else {
      this.placesService.toggleBookmark(this.item);
    }
  }
}
