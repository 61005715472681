import { Component, Input } from '@angular/core';
import { Image } from 'viamondo-core/data';

@Component({
  selector: 'images-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent {
  @Input()
  images: Image[] = [];

  @Input()
  hidePrimary = false;
}
