import { HttpErrorResponse } from '@angular/common/http';

export class ConnectionError extends Error {
  constructor() {
    super('Connection Error');
    Object.setPrototypeOf(this, ConnectionError.prototype);
  }
}

export class BadRequestError extends HttpErrorResponse {
  constructor(response: HttpErrorResponse) {
    super(response);
    Object.setPrototypeOf(this, BadRequestError.prototype);
  }
}

export class UnauthorizedError extends HttpErrorResponse {
  constructor(response: HttpErrorResponse) {
    super(response);
    Object.setPrototypeOf(this, UnauthorizedError.prototype);
  }
}

export class ForbiddenError extends HttpErrorResponse {
  constructor(response: HttpErrorResponse) {
    super(response);
    Object.setPrototypeOf(this, ForbiddenError.prototype);
  }
}

export class NotFoundError extends HttpErrorResponse {
  constructor(response: HttpErrorResponse) {
    super(response);
    Object.setPrototypeOf(this, NotFoundError.prototype);
  }
}

export class ServerError extends HttpErrorResponse {
  constructor(response: HttpErrorResponse) {
    super(response);
    Object.setPrototypeOf(this, ServerError.prototype);
  }
}

export class GenericHttpError extends HttpErrorResponse {
  constructor(response: HttpErrorResponse) {
    super(response);
    Object.setPrototypeOf(this, GenericHttpError.prototype);
  }
}
