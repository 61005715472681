<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Choose location</ion-title>
    <ion-buttons slot="end" *ngIf="allowClose">
      <ion-button (click)="back()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content [class.show-background]="results.length > 0">
  <ng-container *ngIf="allowCurrentLocation">
    <ion-button class="current-location" expand="block" color="secondary" (click)="getCurrentLocation()">
      <ion-icon src="/assets/fontawesome/solid/location.svg" slot="start"></ion-icon>
      Use my current location
    </ion-button>
    <ion-text *ngIf="geoLocationError" color="danger" class="ion-padding ion-text-center"
      >Unable to get your current location. Please check your permissions and try again.</ion-text
    >

    <p class="center-or ion-text-center">OR</p>
  </ng-container>

  <ion-searchbar placeholder="Search for a city" [debounce]="50" [spellcheck]="true" (ionChange)="onSearchInput($event)"></ion-searchbar>

  <p *ngIf="!searching && searchTerm" class="results-text ion-margin">
    {{ results.length | pluralize:'No results matching':'1 result matching':'{} results matching' }}:
    <span class="selected">"{{ searchTerm }}"</span>
  </p>

  <div *ngIf="searching" class="my-spinner">
    <ion-spinner></ion-spinner>
  </div>
  <div *ngIf="results.length === 0" class="blank-location">
    <img src="/assets/img/location_search.png" alt="" class="no-background" width="200" height="148.047" />
    <p>Search for and select a city</p>
  </div>

  <ion-list *ngIf="results.length > 0" class="ion-no-padding">
    <ion-item *ngFor="let r of results" detail-push class="ion-text-wrap" (click)="choose(r)" tappable>
      <ion-fab-button size="small" color="dark" (click)="openMap(r, $event)" slot="start">
        <ion-icon name="map"></ion-icon>
      </ion-fab-button>
      <ion-label>
        <h2>{{ r.city }} <span *ngIf="r.street">({{ r.street }})</span></h2>
        <p><span *ngIf="r.state">{{ r.state }}, </span>{{ r.country }}</p>
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>
