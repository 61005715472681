import { Pipe, PipeTransform } from '@angular/core';
import { ISOString } from 'viamondo-core/data';

@Pipe({
  name: 'timeago'
})
export class TimeagoPipe implements PipeTransform {
  transform(value: ISOString): string {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29)
        // less than 30 seconds ago will show as 'Just now'
        return 'Just now';
      const intervals: { name: string; totalSeconds: number }[] = [
        { name: 'year', totalSeconds: 31536000 },
        { name: 'month', totalSeconds: 2592000 },
        { name: 'week', totalSeconds: 604800 },
        { name: 'day', totalSeconds: 86400 },
        { name: 'hour', totalSeconds: 3600 },
        { name: 'minute', totalSeconds: 60 },
        { name: 'second', totalSeconds: 1 }
      ];
      let counter: number;
      for (const interval of intervals) {
        counter = Math.floor(seconds / interval.totalSeconds);
        if (counter > 0) {
          return `${counter} ${interval.name}${counter > 1 ? 's' : ''} ago`;
        }
      }
    }
    return value;
  }
}
