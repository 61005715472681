<nav>
  <ion-toolbar color="primary" *ngIf="isLoggedIn | async">
    <ion-searchbar
      placeholder="City, country..."
      [debounce]="50"
      [value]="searchTerm | async"
      [spellcheck]="true"
      (ionChange)="onSearchInput($event)"
      showCancelButton="focus"
    ></ion-searchbar>
    <ion-buttons slot="start">
      <ion-button routerLink="/">
        <img
          src="/assets/img/viamondo-logo-text.svg"
          alt="ViaMondo"
          class="no-background"
          width="138"
          height="30"
          fetchpriority="high"
          loading="eager"
        />
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button (click)="showNotifications($event)">
        <ion-icon slot="icon-only" name="notifications"></ion-icon>
        <ion-badge color="danger" *ngIf="notifications | async">{{ notifications | async }}</ion-badge>
      </ion-button>
      <ion-button routerLink="/tabs/profile">
        <ion-icon slot="icon-only" name="person"></ion-icon>
      </ion-button>
      <ion-button routerLink="/tabs/search">
        <ion-icon slot="icon-only" name="compass"></ion-icon>
      </ion-button>
      <ion-button routerLink="/tabs/bookmarks">
        <ion-icon slot="icon-only" name="bookmark"></ion-icon>
      </ion-button>
      <ion-button routerLink="/settings">
        <ion-icon slot="icon-only" name="settings"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar color="primary" *ngIf="(isLoggedIn | async) === false">
    <ion-buttons slot="start">
      <ion-button routerLink="/">
        <img
          src="/assets/img/viamondo-logo-text.svg"
          alt="ViaMondo"
          class="no-background"
          width="138"
          height="30"
          fetchpriority="high"
          loading="eager"
        />
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button routerLink="/session/register">
        <ion-icon slot="start" name="person-add"></ion-icon>
        Sign up
      </ion-button>
      <ion-button routerLink="/session/login">
        <ion-icon slot="start" name="log-in"></ion-icon>
        Login
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</nav>
