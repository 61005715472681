<ng-container [ngSwitch]="itemType">
  <ng-container *ngSwitchCase="'Trip'">
    <ion-item *ngIf="!isOnline" lines="none">
      <ion-label class="ion-text-wrap">You are in offline mode. Go online to see the complete list of all of the trips.</ion-label>
    </ion-item>
    <ion-item *ngIf="isOnline && !loading && displayedTrips.length === 0" lines="none">
      <ion-label class="ion-text-wrap">No trips.</ion-label>
    </ion-item>
    <ion-list *ngIf="loading" class="ion-no-padding">
      <trips-item *ngFor="let i of 8 | range"></trips-item>
    </ion-list>
    <ion-list>
      <trips-item *ngFor="let trip of displayedTrips" [trip]="trip" [usage]="usage"></trips-item>
    </ion-list>
  </ng-container>
  <ng-container *ngSwitchCase="'Place'">
    <ion-item *ngIf="!isOnline" lines="none">
      <ion-label class="ion-text-wrap">You are in offline mode. Go online to see the complete list of all of the places.</ion-label>
    </ion-item>
    <ion-item *ngIf="isOnline && !loading && displayedPlaces.length === 0" lines="none">
      <ion-label class="ion-text-wrap">No places.</ion-label>
    </ion-item>
    <ion-list *ngIf="loading" class="ion-no-padding">
      <places-item *ngFor="let i of 8 | range"></places-item>
    </ion-list>
    <ion-list>
      <places-item *ngFor="let place of displayedPlaces" [place]="place" [usage]="usage"></places-item>
    </ion-list>
  </ng-container>
</ng-container>

<ion-infinite-scroll threshold="100px" (ionInfinite)="loadMoreEnd($event)" position="bottom" [disabled]="loadMoreDisabled">
  <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data..."> </ion-infinite-scroll-content>
</ion-infinite-scroll>
