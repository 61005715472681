<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Create Trip</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="back()">
        <ion-icon name="close" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <form [formGroup]="form" novalidate>
    <ion-item>
      <ion-icon src="/assets/fontawesome/solid/suitcase.svg" slot="start"></ion-icon>
      <ion-label position="stacked">Name</ion-label>
      <ion-input formControlName="name" placeholder="City-Trip to Berlin with my girlfriends"></ion-input>
    </ion-item>
    <forms-datetime-input
      formControlName="startDate"
      label="Start date (optional)"
      displayFormat="dd MMM yyyy"
      presentation="date"
      [errors]="form.controls['startDate'].errors"
    ></forms-datetime-input>
    <forms-datetime-input
      formControlName="endDate"
      label="End date (optional)"
      displayFormat="dd MMM yyyy"
      presentation="date"
      [errors]="form.controls['endDate'].errors"
    ></forms-datetime-input>
    <ion-item>
      <ion-icon src="/assets/fontawesome/solid/align-left.svg" slot="start"></ion-icon>
      <ion-label position="floating">Description</ion-label>
      <ion-textarea formControlName="description" placeholder="Describe your trip..."></ion-textarea>
    </ion-item>
    <forms-privacy-input formControlName="privacy" element="trip" [errors]="form.controls['privacy'].errors"></forms-privacy-input>
    <images-list-input [images]="trip.images"></images-list-input>
  </form>
</ion-content>
<ion-footer class="full-button">
  <ion-button expand="full" color="primary" class="ion-no-margin" (click)="save()">Save</ion-button>
</ion-footer>
