import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Privacy } from 'viamondo-core/data';

@Component({
  selector: 'forms-privacy-modal',
  templateUrl: './privacy-modal.component.html',
  styleUrls: ['./privacy-modal.component.scss']
})
export class PrivacyModalComponent {
  readonly GROUP_NAMES = ['family', 'friends', 'acquaintances', 'others'];
  groups = {};
  mode: 'public' | 'private' | 'groups';

  @Input()
  set privacy(value: Privacy) {
    this.mode = value.mode;
    value.groups.forEach(group => (this.groups[group] = true));
  }

  @Input()
  item: string;

  constructor(private modalController: ModalController) {}

  back(): void {
    this.modalController.dismiss({
      mode: this.mode,
      groups: this.GROUP_NAMES.map(name => (this.groups[name] ? name : null)).filter(name => name !== null)
    });
  }

  selectAll(flag: boolean): void {
    this.GROUP_NAMES.forEach(name => (this.groups[name] = flag));
  }

  modeChanged(event: any): void {
    if (event.detail.value !== 'on') {
      this.mode = event.detail.value;
    }
  }
}
