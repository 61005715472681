import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SocialUser, UsersService } from 'viamondo-core/data';

@Component({
  selector: 'app-group-selector-modal',
  templateUrl: './group-selector.modal.html',
  styleUrls: ['./group-selector.modal.scss']
})
export class GroupSelectorModal {
  readonly groups = ['family', 'friends', 'acquaintances', 'others'];
  isBlocked: boolean;
  hasBlockedCurrent: boolean;
  user: SocialUser;

  @Input()
  set otherUser(value: SocialUser) {
    this.user = value;
    // TODO: Handle blocked users properly
    // const currentUser = this.usersService.getCurrentUser();
    // this.isBlocked = value.isBlockedBy(currentUser._id);
    // this.hasBlockedCurrent = currentUser.isBlockedBy(value._id);
  }

  constructor(private modalController: ModalController, private usersService: UsersService) {}

  back(): void {
    this.modalController.dismiss();
  }

  selectGroup(group: string): void {
    this.usersService.follow(this.user, group).subscribe(() => this.modalController.dismiss());
  }

  unfollow(): void {
    this.usersService.unfollow(this.user).subscribe(() => this.modalController.dismiss());
  }

  block(): void {
    this.usersService.block(this.user).subscribe(() => this.modalController.dismiss());
  }

  unblock(): void {
    this.usersService.unblock(this.user).subscribe(() => this.modalController.dismiss());
  }
}
