import { MinimalTripTO, MinimalTripWithParticipantsTO } from '../../api';

import { Image } from './image';
import { ISOString } from './isostring';
import { MinimalUser } from './minimal-user';

export class MinimalTrip {
  _id: string;
  name: string;
  startDate?: ISOString;
  endDate?: ISOString;
  duration?: number;
  coverImage?: Image;
  creator?: MinimalUser;

  static fromBackend(backendTrip: MinimalTripTO | MinimalTripWithParticipantsTO): MinimalTrip {
    const trip = new MinimalTrip();
    trip._id = backendTrip._id;
    trip.name = backendTrip.name;
    trip.startDate = backendTrip.startDate;
    trip.endDate = backendTrip.endDate;
    trip.duration = backendTrip.duration;
    trip.coverImage = (backendTrip.images || []).find(img => img.primary);

    if (MinimalTrip.isWithParticipants(backendTrip)) {
      trip.creator = backendTrip.participants[0];
    }
    return trip;
  }

  private static isWithParticipants(trip: MinimalTripTO | MinimalTripWithParticipantsTO): trip is MinimalTripWithParticipantsTO {
    return (trip as MinimalTripWithParticipantsTO).participants !== undefined;
  }
}
