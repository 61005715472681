import { UserBioTO } from '../../api';

import { GeoLocation } from './geo-location';
import { Image } from './image';
import { MinimalUser } from './minimal-user';

export class SocialUser implements MinimalUser {
  _id: string;
  firstName: string;
  lastName: string;
  location: GeoLocation;
  profileImage: Image;
  group?: string;

  constructor(userValues: UserBioTO, group?: string) {
    this._id = userValues._id;
    this.firstName = userValues.firstName;
    this.lastName = userValues.lastName;
    this.location = userValues.location;
    this.profileImage = userValues.profileImage;
    this.group = group;
  }
}
