import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MinimalUser } from 'viamondo-core/data';

@Component({
  selector: 'users-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input()
  user: MinimalUser;

  constructor(private router: Router) {}

  viewProfile(): void {
    this.router.navigateByUrl(`/users/${this.user._id}`);
  }
}
