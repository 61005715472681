import { AbstractControl } from '@angular/forms';
import { Privacy } from 'viamondo-core/data';

const GROUP_NAMES = ['family', 'friends', 'acquaintances', 'others'];

export const PrivacyValidator = (control: AbstractControl): null | { validPrivacy: string } => {
  if (!control.value) {
    return null;
  }

  const privacy: Privacy = control.value;

  if (privacy.mode === 'private' || privacy.mode === 'public') {
    return privacy.groups.length === 0 ? null : { validPrivacy: 'Groups must be empty for private and public.' };
  }

  if (privacy.mode === 'groups') {
    if (privacy.groups.length === 0) {
      return { validPrivacy: 'Groups must contain at least one group.' };
    }
    const unknownGroupNames = privacy.groups.filter(g => !GROUP_NAMES.includes(g));
    if (unknownGroupNames.length > 0) {
      return { validPrivacy: `Unknown groups: ${unknownGroupNames.join(', ')}.` };
    }
    return null;
  }

  return { validPrivacy: `Invalid privacy mode: ${privacy.mode}.` };
};
