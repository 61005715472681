<ion-item *ngIf="trip" tappable [routerLink]="['/', 'trips', trip._id]">
  <ion-thumbnail slot="start">
    <img
      height="56"
      width="56"
      [images-src]="trip.coverImage ?? '/assets/img/trip-no-image.png'"
      data-gravity="center"
      data-quality="eco"
      alt=""
      loading="lazy"
    />
  </ion-thumbnail>
  <ion-label>
    <h2>{{ trip.name }}</h2>
    <p>
      <span *ngIf="trip.startDate" class="align-icon-and-text">
        <ion-icon name="calendar"></ion-icon>
        <time [dateTime]="trip.startDate" class="ion-padding-left">{{ trip.startDate | date : 'MMMM yyyy' }}</time></span
      >
      <span *ngIf="usage === 'bookmarks'" class="align-icon-and-text">
        <ion-icon name="person"></ion-icon> <span>{{ trip.creator?.firstName }} {{ trip.creator?.lastName }}</span>
      </span>
    </p>
  </ion-label>
</ion-item>
<ion-item *ngIf="!trip" class="skeleton">
  <ion-thumbnail slot="start">
    <img src="/assets/img/trip-no-image.png" alt="" height="56" width="56" />
  </ion-thumbnail>
  <ion-label>
    <ion-skeleton-text width="100px"></ion-skeleton-text>
    <ion-skeleton-text width="80px"></ion-skeleton-text>
    <ion-skeleton-text *ngIf="usage === 'bookmarks'" width="120px"></ion-skeleton-text>
  </ion-label>
</ion-item>
