import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { SocialUser, UsersService } from 'viamondo-core/data';

@Component({
  selector: 'users-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {
  @Input()
  user: SocialUser;
  showGroupButton: boolean;

  constructor(private userService: UsersService, private router: Router, private modalController: ModalController) {}

  ngOnInit(): void {
    const currentUser = this.userService.getCurrentUser();
    this.showGroupButton = this.user ? currentUser._id !== this.user._id : true;
  }

  showUser(): void {
    this.router.navigate(['/', 'users', this.user._id]).then(() => this.modalController.dismiss());
  }
}
