import { Component, forwardRef, Input, OnChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IonicSlides } from '@ionic/angular';
import SwiperCore, { Autoplay, Keyboard, Navigation, Pagination, Scrollbar } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { CategoryTag, Tag } from 'viamondo-core/data';
import { CategoriesService, CategoryInfo } from 'viamondo-core/places';

SwiperCore.use([Autoplay, Keyboard, Navigation, Pagination, Scrollbar, IonicSlides]);

@Component({
  selector: 'places-tags-selector',
  templateUrl: './tags-selector.component.html',
  styleUrls: ['./tags-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagsSelectorComponent),
      multi: true
    }
  ]
})
export class TagsSelectorComponent implements ControlValueAccessor, OnChanges {
  constructor(private categoriesService: CategoriesService) {}

  @Input()
  category: string;

  @ViewChild(SwiperComponent, { static: false })
  slides?: SwiperComponent;

  tags: CategoryTag[] = [];
  selectedTags: any = {};
  private info: CategoryInfo;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private propagateChange = (_: any) => undefined;

  ngOnChanges(): void {
    this.tags = this.categoriesService.availableTags(this.category);
    this.info = this.categoriesService.info(this.category);
  }

  toggleSelect(name: string, option: string): void {
    if (this.selectedTags[name] === undefined) {
      this.selectedTags[name] = [];
    }
    const index = this.selectedTags[name].indexOf(option);
    if (index === -1) {
      this.selectedTags[name].push(option);
    } else {
      this.selectedTags[name].splice(index, 1);
    }
    this.propagateChange(this.convert(this.selectedTags));
  }

  getTagStyle(name: string, option: string): any {
    const isSelected = this.selectedTags[name] && this.selectedTags[name].indexOf(option) !== -1;
    return {
      'background-color': isSelected ? this.info.color : 'transparent',
      'color': isSelected ? 'white' : 'black'
    };
  }

  writeValue(value: any): void {
    if (value !== undefined && value !== null) {
      value.forEach(element => {
        this.selectedTags[element.name] = element.values;
      });
    }
  }

  registerOnChange(fn: () => any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(): void {}

  private convert(selectedTags: any): Tag[] {
    const keys = Object.keys(selectedTags);
    const resultArray = [];
    for (const k of keys) {
      resultArray.push({
        name: k,
        values: selectedTags[k]
      });
    }
    return resultArray;
  }
}
