import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[app-is-submitting]'
})
export class IsSubmittingDirective {
  @Input('app-is-submitting')
  set isSubmitting(value: boolean) {
    if (value) {
      const spinnerElement = this.document.createElement('ion-spinner');
      spinnerElement.className = 'button-spinner';
      this.renderer.insertBefore(this.elementRef.nativeElement, spinnerElement, this.elementRef.nativeElement.firstChild);
    } else {
      const spinnerElement = this.elementRef.nativeElement.querySelector('.button-spinner');
      if (spinnerElement) {
        this.renderer.removeChild(this.elementRef.nativeElement, spinnerElement);
      }
    }
  }

  constructor(private elementRef: ElementRef, private renderer: Renderer2, @Inject(DOCUMENT) private document: Document) {}
}
