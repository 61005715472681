import { Injectable } from '@angular/core';
import { latLng, latLngBounds, MapOptions, Marker, marker, MarkerClusterGroupOptions, tileLayer, TileLayer } from 'leaflet';
import { GeoLocation, MinimalPlace, MinimalVenue, Place, Trip, Venue } from 'viamondo-core/data';
import { CategoriesService } from 'viamondo-core/places';

import { environment } from '../../../../environments/environment';
import { MultiMapConfig } from '../models/multi-map-config';
import { SingleMapConfig } from '../models/single-map-config';

import { fontAwesomeIcon } from './font-awesome.icon';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  private readonly MIN_ZOOM = 2;
  private readonly MAX_ZOOM = 20;
  private mapBoxConfig: any;
  private online = true;

  constructor(private categoriesService: CategoriesService) {
    this.mapBoxConfig = environment.mapboxConfig;
  }

  getTileLayer(): TileLayer {
    return tileLayer(this.mapBoxConfig.url + this.mapBoxConfig.token, {
      minZoom: this.MIN_ZOOM,
      maxZoom: this.MAX_ZOOM,
      attribution: `© <a href="https://www.mapbox.com/about/maps/" rel="noopener noreferrer" target="_system">Mapbox</a>
                    © <a href="http://www.openstreetmap.org/copyright" rel="noopener noreferrer" target="_system">OpenStreetMap</a>`,
      id: 'mapbox.streets'
    });
  }

  getMapForVenue(venue: Venue): SingleMapConfig {
    return {
      options: this.getLeafletOptions(true),
      center: latLng(venue.location.lat, venue.location.lng),
      zoom: 14,
      markers: [this.createVenueMarker(venue)]
    };
  }

  getMapForSinglePoint(location: GeoLocation): SingleMapConfig {
    return {
      options: this.getLeafletOptions(true),
      center: latLng(location.lat, location.lng),
      zoom: 5,
      markers: [this.createDefaultMarker(location)]
    };
  }

  getMapForTrip(trip: Trip): MultiMapConfig {
    return this.getMapForPlaces(trip.places);
  }

  getMapForPlaces(places: Place[] | MinimalPlace[]): MultiMapConfig {
    const layer = [];
    const boundsArray = [];

    for (const place of places) {
      boundsArray.push(latLng(place.venue.location.lat, place.venue.location.lng));
      layer.push(this.createPlaceMarker(place));
    }

    return {
      options: this.getLeafletOptions(true),
      markerClusterOptions: this.getMarkerClusterOptions(),
      bounds: latLngBounds(boundsArray),
      boundsOptions: { padding: [50, 50] },
      layer
    };
  }

  getMapForVenues(venues: Venue[]): MultiMapConfig {
    const layer = [];
    const boundsArray = [];

    for (const venue of venues) {
      boundsArray.push(latLng(venue.location.lat, venue.location.lng));
      layer.push(this.createVenueMarker(venue));
    }

    return {
      options: this.getLeafletOptions(true),
      markerClusterOptions: this.getMarkerClusterOptions(),
      bounds: latLngBounds(boundsArray),
      boundsOptions: { padding: [50, 50] },
      layer
    };
  }

  private getLeafletOptions(dragging: boolean): MapOptions {
    return {
      center: latLng(47.36667, 8.55),
      dragging,
      zoom: 8,
      minZoom: this.MIN_ZOOM,
      maxZoom: this.MAX_ZOOM,
      touchZoom: dragging,
      scrollWheelZoom: dragging,
      zoomControl: this.online && dragging,
      doubleClickZoom: this.online
    };
  }

  private getMarkerClusterOptions(): MarkerClusterGroupOptions {
    return {
      spiderfyOnMaxZoom: false,
      disableClusteringAtZoom: 13,
      chunkedLoading: true
    };
  }

  private createVenueMarker(venue: Venue): Marker {
    const info = this.categoriesService.info(venue.category || 'other');
    const message = this.getMarkerLabelContent(venue, `/venues/${venue._id}`, info.color);
    return marker(latLng(venue.location.lat, venue.location.lng), {
      icon: fontAwesomeIcon(info.mapIcon),
      title: this.getTitle(venue)
    }).bindPopup(message);
  }

  private createPlaceMarker(place: Place | MinimalPlace): Marker {
    const info = this.categoriesService.info(place.venue.category || 'other');
    const message = this.getMarkerLabelContent(place.venue, `/places/${place._id}`, info.color);
    return marker(latLng(place.venue.location.lat, place.venue.location.lng), {
      icon: fontAwesomeIcon(info.mapIcon),
      title: this.getTitle(place.venue)
    }).bindPopup(message);
  }

  private createDefaultMarker(location: GeoLocation): Marker {
    const info = this.categoriesService.info('cities');
    return marker(latLng(location.lat, location.lng), {
      icon: fontAwesomeIcon(info.mapIcon)
    });
  }

  private getMarkerLabelContent(venue: MinimalVenue, linkUrl: string, color: string): string {
    return `
    <p>
      <a href="${linkUrl}" style="color: ${color}">${venue.name}</a><br>
      ${venue.location.formatted}
    </p>`;
  }

  private getTitle(venue: MinimalVenue): string {
    return `${venue.name}, ${venue.location.formatted}`;
  }
}
