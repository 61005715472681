<swiper [navigation]="true" *ngIf="tags.length > 0">
  <ng-template swiperSlide *ngFor="let tag of tags">
    <div class="custom-slide">
      <h4 class="title">
        Tag: <span>{{ tag.name }}</span>
      </h4>
      <ul class="option-list">
        <li (click)="toggleSelect(tag.name, option)" tappable [ngStyle]="getTagStyle(tag.name, option)" *ngFor="let option of tag.options">
          {{ option }}
        </li>
      </ul>
    </div>
  </ng-template>
</swiper>
