<ion-header>
  <ion-toolbar color="primary">
    <ion-title>{{ user.firstName }} {{ user.lastName }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="back()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <p *ngIf="!hasBlockedCurrent && !isBlocked" class="info">Select which group {{ user.firstName }} should belong to.</p>
  <ion-list *ngIf="!hasBlockedCurrent && !isBlocked">
    <ion-item (click)="selectGroup(group)" *ngFor="let group of groups">
      <ion-label text-capitalize>{{ group }}</ion-label>
      <ion-icon *ngIf="user.group === group" name="checkmark" item-end></ion-icon>
    </ion-item>
    <ion-item *ngIf="user.group" (click)="unfollow()" tappable>
      <ion-label ion-text color="danger">Unfollow</ion-label>
    </ion-item>
  </ion-list>

  <ion-list>
    <ion-button
      class="ion-text-center ion-text-uppercase"
      fill="outline"
      expand="block"
      color="danger"
      (click)="block()"
      *ngIf="!isBlocked"
    >
      Block
    </ion-button>
    <ion-button
      class="ion-text-center ion-text-uppercase"
      fill="outline"
      expand="block"
      class="danger"
      (click)="unblock()"
      *ngIf="isBlocked"
    >
      Unblock
    </ion-button>
  </ion-list>
</ion-content>
