import { DivIcon, DivIconOptions, Point } from 'leaflet';
import * as L from 'leaflet';

export interface MapIconOptions {
  icon: string;
  markerColor: string;
}

export interface FontAwesomeOptions extends DivIconOptions {
  icon?: string;
  iconColor?: string;
  markerColor?: string;
  extraClasses?: string;
  bgPos?: any;
  className?: string;
  shadowAnchor?: any;
  iconAnchor?: any;
}

export class FontAwesomeIcon extends DivIcon {
  override readonly options: FontAwesomeOptions;

  constructor(options: FontAwesomeOptions) {
    super(options);
  }

  override createIcon(): HTMLDivElement {
    const div = document.createElement('div');
    const options = this.options;

    if (options.icon) {
      div.innerHTML = this._createInner();
    }

    if (options.bgPos) {
      div.style.backgroundPosition = -(options.bgPos as any).x + 'px ' + -(options.bgPos as any).y + 'px';
    }

    this._setIconStyles(div, 'icon-' + options.markerColor);
    return div;
  }

  _createInner(): string {
    let iconColorClass = '';
    let iconColorStyle = '';

    if (this.options.iconColor) {
      if (this.options.iconColor === 'white' || this.options.iconColor === 'black') {
        iconColorClass = 'icon-' + this.options.iconColor;
      } else {
        iconColorStyle = "style='color: " + this.options.iconColor + "' ";
      }
    }

    return `<div ${iconColorStyle} class="${this.options.extraClasses} ${iconColorClass}">
      <ion-icon src="/assets/fontawesome/solid/${this.options.icon}.svg">
    </div>`;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  _setIconStyles(img: any, name: string): void {
    const size = L.point(this.options[name === 'shadow' ? 'shadowSize' : 'iconSize']);
    let anchor = name === 'shadow' ? L.point(this.options.shadowAnchor || this.options.iconAnchor) : L.point(this.options.iconAnchor);

    if (!anchor && size) {
      anchor = size.divideBy(2);
    }

    img.className = 'awesome-marker-' + name + ' ' + this.options.className;

    if (anchor) {
      img.style.marginLeft = -anchor.x + 'px';
      img.style.marginTop = -anchor.y + 'px';
    }

    if (size) {
      img.style.width = size.x + 'px';
      img.style.height = size.y + 'px';
    }
  }

  override createShadow(): HTMLDivElement {
    const div = document.createElement('div');
    this._setIconStyles(div, 'shadow');
    return div;
  }
}

export const fontAwesomeIcon: (iconOptions: MapIconOptions) => FontAwesomeIcon = (iconOptions: MapIconOptions) => {
  const defaultOptions = {
    iconSize: new Point(35, 45),
    iconAnchor: new Point(17, 42),
    popupAnchor: new Point(1, -32),
    shadowAnchor: new Point(10, 12),
    shadowSize: new Point(36, 16),
    className: 'awesome-marker',
    extraClasses: 'icon-wrapper',
    icon: 'home',
    markerColor: 'blue',
    iconColor: 'white'
  };

  return new FontAwesomeIcon({ ...defaultOptions, ...iconOptions });
};
