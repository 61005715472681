import { differenceInCalendarDays, parseISO } from 'date-fns';

import { ImageTO, UserBioTO } from '../../api';

import { BasicTypes } from './basic-types.enum';
import { Image } from './image';
import { ISOString } from './isostring';
import { MinimalUser } from './minimal-user';
import { Privacy } from './privacy';

export abstract class Basic {
  readonly basicType: BasicTypes;
  _id: string;
  creator: MinimalUser;
  privacy?: Privacy; // only set if it is your own entity
  images: Image[] = [];
  coverImage?: Image;
  coverImageIndex?: number;

  likes: string[] = [];

  // dynamic properties set through BasicService.initalize()
  isOwn: boolean;
  isLiked: boolean;
  isBookmarked: boolean;

  static dateDiffInDays(a: ISOString, b: ISOString): number {
    if (!a || !b) {
      return 0;
    }
    return differenceInCalendarDays(parseISO(b), parseISO(a));
  }

  constructor(basicType: BasicTypes) {
    this.basicType = basicType;
  }

  setCreator(user?: UserBioTO | string): void {
    if (!user) {
      this.creator = {
        firstName: '',
        lastName: '',
        profileImage: undefined,
        _id: ''
      };
    } else if (typeof user === 'string') {
      this.creator = {
        firstName: '',
        lastName: '',
        profileImage: undefined,
        _id: user
      };
    } else {
      this.creator = {
        firstName: user.firstName,
        lastName: user.lastName,
        profileImage: user.profileImage,
        _id: user._id
      };
    }
  }

  setCommon(id: string, images: ImageTO[]): void {
    this._id = id;
    this.images = images;
    this.setPrimaryImage();
  }

  setReactions(likes: string[]): void {
    this.likes = likes;
  }

  updateValues(formValues: any): void {
    Object.assign(this, formValues);
  }

  setInitialPrivacy(): void {
    this.privacy = {
      mode: 'groups',
      groups: ['family', 'friends']
    };
  }

  toggleLike(currentUserId: string): void {
    const index = this.likes.indexOf(currentUserId);
    if (index >= 0) {
      this.likes.splice(index, 1);
      this.isLiked = false;
    } else {
      this.likes.push(currentUserId);
      this.isLiked = true;
    }
  }

  private setPrimaryImage(): void {
    let currentPrimary = null;
    for (let i = 0; i < 5; i++) {
      if (this.images[i] && this.images[i].primary) {
        currentPrimary = this.images[i];
        this.coverImageIndex = i;
      }
    }
    if (this.images.length && currentPrimary === null) {
      currentPrimary = this.images[0];
      this.coverImageIndex = 0;
    }
    this.coverImage = currentPrimary;
  }
}
