import { Directive, HostListener, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Venue } from 'viamondo-core/data';

import { VenueInfoModal } from '../modals/venue-info/venue-info.modal';

@Directive({
  selector: '[places-venue-info]'
})
export class VenueInfoDirective {
  @Input('places-venue-info')
  venue: Venue;

  constructor(private modalController: ModalController) {}

  @HostListener('click')
  async onClick(): Promise<void> {
    const modal = await this.modalController.create({
      component: VenueInfoModal,
      componentProps: {
        venue: this.venue
      }
    });
    return await modal.present();
  }
}
