<div
  *ngIf="place"
  tappable
  class="ion-no-padding place-item"
  [routerLink]="['/', 'places', place._id]"
  [ngClass]="{ 'has-image': place.coverImage }"
>
  <places-category-bubble [category]="place.venue.category"></places-category-bubble>
  <h2 class="ellipsis">{{ place.venue.name }}</h2>
  <p *ngIf="usage === 'default'">
    <span class="location">{{ place.venue.location | location }}</span
    ><br *ngIf="place.venue.location.country" />
    <span class="align-icon-and-text">
      <ion-icon name="calendar"></ion-icon> <time [dateTime]="place.startDate">{{ place.startDate | date : 'dd MMMM yyyy' }}</time>
    </span>
    <places-rating [readOnlyValue]="place.rating"></places-rating>
  </p>
  <p *ngIf="usage === 'bookmarks'">
    <span class="location">{{ place.venue.location | location }}</span>
    <span class="align-icon-and-text">
      <ion-icon name="person"></ion-icon>
      <span>{{ place.creator?.firstName }} {{ place.creator?.lastName }}</span>
    </span>
  </p>
  <img
    *ngIf="place.coverImage"
    height="60"
    width="60"
    [images-src]="place.coverImage"
    data-gravity="center"
    data-quality="eco"
    loading="lazy"
  />
</div>
<div *ngIf="!place" class="place-item skeleton">
  <places-category-bubble category="skeleton"></places-category-bubble>
  <ion-skeleton-text width="160px"></ion-skeleton-text>
  <ion-skeleton-text width="80px"></ion-skeleton-text>
  <ion-skeleton-text width="120px"></ion-skeleton-text>
</div>
