import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DatetimeCustomEvent, InputCustomEvent, Platform } from '@ionic/angular';
import { format, formatISO, parse, parseISO } from 'date-fns';

let nextId = 0;

@Component({
  selector: 'forms-datetime-input',
  templateUrl: './datetime-input.component.html',
  styleUrls: ['./datetime-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatetimeInputComponent),
      multi: true
    }
  ]
})
export class DatetimeInputComponent implements OnInit, ControlValueAccessor {
  @Input()
  id = `open-datetime-${nextId++}`;

  @Input()
  label: string;

  @Input()
  presentation: 'date-time' | 'date' = 'date-time';

  @Input()
  errors: any;

  @Input()
  displayFormat = 'dd MMM yyyy HH:mm';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  propagateChange = (_: unknown): undefined => undefined;
  propagateBlur = (): undefined => undefined;

  public dateValue?: string;
  public formattedDate?: string;
  public isMobile = true;

  constructor(private platform: Platform) {}

  ngOnInit(): void {
    this.isMobile = this.platform.is('mobile');
  }

  get invalid(): boolean {
    return this.errors && Object.keys(this.errors).length > 0;
  }

  get nativeInputDateFormat(): string {
    return this.presentation === 'date' ? 'yyyy-MM-dd' : "yyyy-MM-dd'T'HH:mm";
  }

  setFormattedDate(parsedDate: Date): void {
    if (!parsedDate) {
      this.dateValue = undefined;
      this.formattedDate = undefined;
      return;
    }

    if (this.presentation === 'date') {
      this.dateValue = formatISO(parsedDate, { representation: 'date' });
    } else {
      this.dateValue = formatISO(parsedDate);
    }
    this.formattedDate = format(parsedDate, this.isMobile ? this.displayFormat : this.nativeInputDateFormat);
  }

  onBlur(): void {
    this.propagateBlur();
  }

  setDate(e: Event): void {
    const datetimeEvent = e as DatetimeCustomEvent;
    const newDate = Array.isArray(datetimeEvent.detail.value) ? datetimeEvent.detail.value[0] : datetimeEvent.detail.value;
    this.setFormattedDate(parseISO(newDate));
    this.propagateChange(this.dateValue);
  }

  inputChanged(e: Event): void {
    const inputEvent = e as InputCustomEvent;
    try {
      this.setFormattedDate(parse(inputEvent.detail.value, this.nativeInputDateFormat, new Date()));
      this.propagateChange(this.dateValue);
    } catch (error) {
      if (error instanceof RangeError) {
        // Quietly catch RangeErrors: These happen when a date contains 0
      } else {
        throw error;
      }
    }
  }

  writeValue(value: string): void {
    if (value) {
      this.setFormattedDate(parseISO(value));
    }
  }

  registerOnChange(fn: () => any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    this.propagateBlur = fn;
  }
}
